import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	Sentry.init({
		dsn: 'https://cfea481b95b44b8bae3467365db25ce2@o509096.ingest.sentry.io/5602774',
		autoSessionTracking: true,
		integrations: [
			// Registers and configures the Tracing integration,
			// which automatically instruments your application to monitor its
			// performance, including custom Angular routing instrumentation
			// @ts-ignore
			new Integrations.BrowserTracing({
				tracingOrigins: [
					'localhost',
					'http://alpha.shareastrength.com',
				],
				// @ts-ignore
				routingInstrumentation: Sentry.routingInstrumentation,
			}),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));

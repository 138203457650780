<div class="layout-grid container">
	<div class="navbar-start">
		<a routerLink="/" class="navbar-brand">
			<sas-logo [fill]="navbarColorLight ? '#000' : '#fff' "></sas-logo>
		</a>
	</div><!--/.navbar-start-->

	<div class="navbar-center">
		<span class="current-page-title">{{currentPageTitle}}</span>
		<!-- <app-navigation-static></app-navigation-static> -->
	</div><!--/.navbar-center-->

	<div class="navbar-end">
		<app-searchbar [expanded]="true"></app-searchbar>
		<!-- <ng-container *ngIf="user$|async as user">
			<app-navigation-user *ngIf="!!user" [user]="user" (logout)="logout()"></app-navigation-user>
		</ng-container> -->
	</div><!--/.navbar-end-->
</div>


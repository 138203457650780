import {
	Component,
	OnInit,
	Input,
	ElementRef,
	AfterViewInit,
	HostBinding
} from '@angular/core';

@Component({
	selector: 'sas-card',
	template: '<ng-content></ng-content>',
	styleUrls: ['./card-base.component.scss']
})
export class CardBaseComponent implements OnInit {
	@Input() classes: string[] = [];
	@Input() attributes: string[] = [];

	@Input('class')
	@HostBinding('class')
	className = '';

	@HostBinding('class.card') cardClass = true;
	@HostBinding('class.sas-card') sasCardClass = true;

	constructor(private el: ElementRef) {}

	ngOnInit() {
		this.className += ` ${this.classes.join(' ')}`;
		// this.classes.forEach(_c => this.el.nativeElement.classList.add(_c));
	}
}

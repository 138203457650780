import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	Output,
	EventEmitter,
	ViewChild,
} from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';
import { Observable, Subscription } from 'rxjs';
import { InsightsService } from 'src/app/services/insights.service';
import { TraitInsight } from 'src/app/interfaces/trait-insight';
import { TraitsModalService } from 'src/app/components/traits-modal/traits-modal.service';
import { map } from 'rxjs/operators';

@Component({
	selector: 'sas-trait-modal',
	templateUrl: './trait-modal.component.html',
	styleUrls: ['./trait-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraitModalComponent implements OnInit {
	@Input('traits') traits: Trait[];

	@Input('traitId') traitId: number;

	@Output() dismiss: EventEmitter<void> = new EventEmitter();

	insights$: Observable<{ trait: Trait; insight: TraitInsight }[]>;

	trait: Trait;

	traitContentItems = [
		{
			key: 'sweetspot',
			title: 'Flow',
			description:
				"The FLOW is the positive impact you can bring to a situation when you are using this trait in it's most effective manner.",
		},
		{
			key: 'blindspot',
			title: 'Friction',
			description:
				"The FRICTION is the negative impact you can bring to a situation when you are using this trait in it's most effective manner.",
		},
		{
			key: 'strategy',
			title: 'Strategy',
			description:
				"The strategy is the recommended approach to this trait in order to maximize it's effectivenss.",
		},
	];

	@ViewChild('slider', { static: false }) slider: any;

	constructor(
		private insightsService: InsightsService,
		private traitsModalService: TraitsModalService
	) {}

	ngOnInit() {
		this.trait = this.traits.find((trait) => trait.id === this.traitId);
		this.insights$ = this.insightsService
			.getInsightsForTrait(this.traitId)
			.pipe(
				map((insights) => {
					return insights.map((insight) => ({
						trait: this.trait,
						insight: insight,
					}));
				})
			);
	}

	close(event: any) {
		if (event && !event.target.classList.contains('modal')) {
			return;
		}
		this.dismiss.emit();
	}

	showAllTraits() {
		this.traitsModalService.showTraitModal = true;
		this.dismiss.emit();
	}
}

<app-report-page
	id="cover"
	variant="cover"
	headerText=""
	[footerText]="'&copy; ' + currentYear + ' ShareaStrength LLC.'"
>
	<sas-logo></sas-logo>
</app-report-page>

<ng-container *ngIf="{
	flow: flow$|async,
	friction: friction$|async,
	inBetween: inBetween$|async,
	chartData: amoebaData$|async
} as data">

	<app-report-page
		id="toc"
		variant="toc"
		headerText="Index"
	>
		<h1>Index</h1>
		<ul class="toc">
			<li><a href="#profile" (click)="scrollTo($event, profile)">Your Profile</a> <span class="page-number">{{getPageNumber('profile')}}</span></li>
			<li><a href="#flow" (click)="scrollTo($event, flowPage)">Flow</a> <span class="page-number">{{getPageNumber('flow')}}</span></li>
			<li><a href="#friction" (click)="scrollTo($event, frictionPage)">Friction</a> <span class="page-number">{{getPageNumber('friction')}}</span></li>
			<li><a href="#in-between" (click)="scrollTo($event, inBetweenPage)">In-Between</a> <span class="page-number">{{getPageNumber('in-between')}}</span></li>
			<li><a href="#amoeba" (click)="scrollTo($event, amoebaPage)">The Amoeba</a> <span class="page-number">{{getPageNumber('amoeba')}}</span></li>
			<li><a href="#whats-next" (click)="scrollTo($event, whatsNextPage)">What's next?</a> <span class="page-number">{{getPageNumber('whats-next')}}</span></li>
		</ul>
	</app-report-page>

	<app-report-page
		#profile
		id="profile"
		variant="profile"
		headerText="Profile"
	>
		<div>
			<h2>Your Profile</h2>
			<p>Welcome to your personal profile. This page includes a visual overview of the results of your assessment and what you can do with all the information.</p>
			<p><strong>You can also review the critical components of each trait:</strong></p>
			<p><strong>Your score:</strong> the strength of this trait in your assessment results (out of 100%)</p>
			<p><strong>At Your Best:</strong> how this becomes your superpower</p>
			<p><strong>In Overdrive:</strong> how you might overuse or overpower in your relationships</p>
		</div>
	</app-report-page>

	<!-- Traits -->

	<!-- Flow -->
	<app-report-page
		#flowPage
		id="flow"
		variant="section"
		headerText="Flow"
	>
		<div>
			<h2>Your Flow</h2>
			<p>As you access your report, the first section you will explore is your Flow.  Your "Flow" refers to your top 5 traits, the "thinking and doing" that comes so naturally to you. </p>
			<p>Like traveling downstream or rolling downhill, these are the parts of you that come out effortlessly, that bring you energy when you use them, and can get you in that "flow state", whether you are busy at work or grooving at home.</p>
			<p>Your flow is made up of the traits that are the most dominant aspects of your personality, right now.  These are your superpowers.  And like every good superhero, you should focus on using them often.  Within this section and others, you will be introduced to the trait score, which shows the relative strength of each trait in your profile.</p>
		</div>
	</app-report-page>

	<ng-container *ngFor="let traitResult of data.flow; let i = index">
		<ng-container *ngTemplateOutlet="traitTemplate;context:{traitResult}"></ng-container>
	</ng-container>

	<!-- Friction -->
	<app-report-page
		#frictionPage
		id="friction"
		variant="section"
		headerText="Friction"
	>
		<div>
			<h2>Your Friction</h2>
			<p>The next part of the report is the opposite of your Flow.  We call this your Friction. </p>
			<p>They are not weaknesses, but rather areas that feel a little less comfortable.</p>
			<p>These are the things that take more energy or effort for you to bring out, kinda like driving a car on square-shaped tires. It's important to know your friction, because spending too much of your time trying to embody these traits will leave you feeling exhausted.</p>
		</div>
	</app-report-page>

	<ng-container *ngFor="let traitResult of data.friction; let i = index">
		<ng-container *ngTemplateOutlet="traitTemplate;context:{traitResult}"></ng-container>
	</ng-container>

	<!-- In-Between -->
	<app-report-page
		#inBetweenPage
		id="in-between"
		variant="section"
		headerText="In-Between"
	>
		<div>
			<h2>Your In-Between</h2>
			<p>In between your friction and your flow is what we aptly call your "In-Between". Depending on the situation, these traits could flex into your strengths or challenges.</p>
			<p>As you review your results, take note of the trait scores of the In-Between.  This could give you an indication of how close they are to your Flow or Friction.</p>
		</div>
	</app-report-page>

	<ng-container *ngFor="let traitResult of data.inBetween; let i = index">
		<ng-container *ngTemplateOutlet="traitTemplate;context:{traitResult}"></ng-container>
	</ng-container>

	<!-- Amoeba -->
	<app-report-page
		#amoebaPage
		id="amoeba"
		variant="section"
		headerText="Amoeba"
	>
		<div>
			<h2>The Amoeba</h2>
			<p>Time to bring it all together using a visual! Think back to Biology class. Remember the amoeba, the one-celled organism that is defined by its "unique movement patterns?" Here you can spot how your 25 traits influence your unique profile - based on their individual strength within you. </p>
			<p>Your flow will show up at the farther distance from the center of the amoeba, indicating stronger presence in your personality. Your friction will fall closer to the center of the amoeba.</p>
			<p>Your amoeba provides you one succinct view into the dynamic aspects of your personality. And just like how you are a unique person with unique perspective, experiences, and talents there are only a few people in the world who have an amoeba shaped like yours.</p>
			<p>So, with all this beautiful uniqueness in mind, now is your chance to share with others!</p>
		</div>
	</app-report-page>

	<!-- Amoeba Chart -->
	<app-report-page id="amoeba-chart" variant="section" headerText="Your Amoeba">
		<sas-chart-amoeba
			*ngIf="data.chartData"
			[debug]="true"
			[data]="data.chartData"
			[options]="amoebaChartOptions"
		></sas-chart-amoeba>
	</app-report-page>

	<!-- What's Next? -->
	<app-report-page
		#whatsNextPage
		id="whats-next"
		variant="section"
		headerText="What's Next?"
	>
		<div>
			<h2>What's Next?</h2>
			<p>Now you're ready to invite friends, colleagues and family to take the assessment!  They will also discover what energizes them at work and home and gain insights for using their strengths with ease. </p>
			<p>Sharing allows you to now compare your results, recognizing  what you have in common as well as how you are motivated and talented differently.</p>
			<p>We're thrilled you are a part of the community.  Thanks for listening to this amazingly interesting demo… Now, dig in! </p>
		</div>
	</app-report-page>

</ng-container>

<ng-template #traitTemplate let-traitResult="traitResult">
	<!-- Cover -->
	<app-report-page
		variant="trait-cover"
		[headerText]="traitResult.trait.name_general"
	>
		<div>
			<div class="icon"><svg [appSpriteIcon]="getTraitSafeName(traitResult.trait.name_general)"></svg></div>
			<h2 class="h1">{{traitResult.trait.name_general}}</h2>
			<p><strong>Rank #{{traitResult.rank}}</strong></p>
		</div>
		<div>
			<blockquote style="font-size: 1.25rem; font-weight: 300; font-style: italic;">{{traitResult.trait.haiku.content}}</blockquote>
		</div>
		<div>
			<p style="margin-bottom: 0;">You scored</p>
			<p style="font-size: 3rem; font-weight: 300; line-height: 1;">{{traitResult.dominance}}%</p>
		</div>
	</app-report-page>
	<!-- Detail -->
	<app-report-page
		variant="trait-detail"
		[headerText]="traitResult.trait.name_general"
	>
		<!-- <div>{{traitResult|json}}</div> -->
		<div>
			<p><strong>At Your Best</strong></p>
			<p>{{traitResult.trait.sweetspot.content}}</p>
		</div>
		<div>
			<p><strong>In Overdrive</strong></p>
			<p>{{traitResult.trait.blindspot.content}}</p>
		</div>
		<div>
			<p><strong>Strategy</strong></p>
			<p>{{traitResult.trait.strategy.content}}</p>
		</div>
	</app-report-page>
</ng-template>

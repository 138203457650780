import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-state.model';
import { User } from '../store/user/user.model';
import { UserComparisonActivity } from '../store/user-activity/user-activity.model';

import * as UserActivityActions from '../store/user-activity/user-activity.actions';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../interfaces/user-profile';
import { selectUser, selectUserActivity } from '../store';

@Injectable({
	providedIn: 'root'
})
export class UserActivityService {
	constructor(private http: HttpClient, private store: Store) {}

	logUserComparison(user: User): void {
		this.store
			.select(selectUser)
			.pipe(take(1))
			.subscribe(_user => {
				if (_user.id === user.id) {
					return;
				}
				const {
					id,
					email,
					profile: {
						name,
						first_name,
						last_name,
						dob,
						sex,
						marital_status,
						profession,
						city,
						zip,
						image_path
					}
				} = user;
				const comparisonActivity: UserComparisonActivity = {
					user: {
						id,
						email,
						profile: {
							name,
							first_name,
							last_name,
							dob,
							sex,
							marital_status,
							profession,
							city,
							zip,
							image_path
						}
					},
					compareDate: new Date().toUTCString()
				};

				this.store.dispatch(
					UserActivityActions.logComparison({
						comparison: comparisonActivity
					})
				);

				this._updateComparisonActivityOnApi();

				return;
			});
	}

	/**
	 * Clear Comparison History in store
	 * This will only be used during logout.
	 */
	clearUserComparisonHistory(): void {
		return this.store.dispatch(UserActivityActions.clear());
	}

	/**
	 * Update the full comparison activity list on
	 * the API.
	 */
	private async _updateComparisonActivityOnApi(): Promise<void> {
		console.log('SEND');
		const res = await this.getUserComparisonActivity()
			.pipe(
				switchMap(activity =>
					this.http.post(
						`${environment.api_base_url}/me/activity-history/comparisons`,
						{ payload: activity }
					)
				)
			)
			.toPromise();
		console.log('RECEIVE', res);
	}

	/**
	 * Retrieve the comparison history from the API.
	 * This should only be used on login.
	 */
	private _loadComparisonActivityFromApi(): Observable<boolean> {
		return this.http
			.get<UserComparisonActivity[]>(
				`${environment.api_base_url}/me/activity-history/comparisons`
			)
			.pipe(
				tap(_a => console.log('Activity', _a)),
				switchMap(comparisons => {
					this.store.dispatch(
						UserActivityActions.loadActivity({
							comparisons
						})
					);
					return of(true);
				})
			);
	}

	getUserComparisonActivity(
		load: boolean = false
	): Observable<UserComparisonActivity[]> {
		return (load ? this._loadComparisonActivityFromApi() : of(true)).pipe(
			switchMap(() =>
				this.store
					.select(selectUserActivity)
					.pipe(map(userActivity => userActivity.comparisons))
			)
		);
	}

	getUserFriendsComparisonActivity(): Observable<
		{ user: User; provider: User }[]
	> {
		return this.http.get<{ user: User; provider: User }[]>(
			`${environment.api_base_url}/me/friends/activity`
		);
	}
}

<ng-container *ngIf="data">
	<sas-card>
		<sas-card-header>
			<strong class="card-title d-block mb-0">
				Insights <i class="fas fa-bolt text-muted"></i>
			</strong>
			<small class="text-muted">
				Trait insights for <strong>{{data[0].trait.name_general}}</strong>
			</small>
		</sas-card-header>
		<sas-card-body>
			<div class="d-flex flex-column flex-lg-row">
				<div *ngFor="let item of data" class="col align-self-stretch insight d-flex flex-column align-items-center mb-3 mb-lg-0">
					<strong>{{item.insight.parsedTitle}}</strong>
					<span *ngIf="item.insight.parsedNumberText" class="h1 mb-3 insight__number text-primary">{{item.insight.parsedNumberText}}</span>
					<div class="progress w-100 mb-3">
						<div [style.width]="item.insight.parsedNumber+'%'" class="progress-bar" role="progressbar" [attr.aria-valuenow]="item.insight.parsedNumberText" aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<small class="insight__text">{{item.insight.insightText}}</small>
				</div>
			</div>
			<!-- <ul class="mb-0">
				<li *ngFor="let item of data">{{item.insight.insightText}}</li>
			</ul> -->
		</sas-card-body>
	</sas-card>
</ng-container>

<!-- @todo revisit named router outlets -->
<ng-container *ngIf="userService.user|async as user">

	<div class="layout-grid container">

		<aside class="sidebar sidebar--main" *ngIf="!fullWidth">
			<router-outlet name="sidebarMain"></router-outlet>
			<app-navigation-static style="margin-bottom:auto" [dark]="true"></app-navigation-static>
			<app-user-tease
				[user]="user"
				[link]="false"
				[isAuthUser]="true"
			></app-user-tease>
		</aside>

		<main class="content" [class.full-width]="fullWidth">
			<router-outlet><!-- main content --></router-outlet>
		</main>

		<aside class="sidebar sidebar--secondary" *ngIf="!fullWidth">
			<router-outlet name="sidebarSecondary"></router-outlet>
			<ng-container *ngIf="userComparisonActivity$|async as userComparisonActivity">
				<!-- actions -->
				<div *ngIf="userComparisonActivity.length; else onboarding">
					<ng-container *ngIf="$viewingReport|async; else compareActivity">
						<!-- share cta -->
						<app-user-share-results [user]="user"></app-user-share-results>
						<!-- video -->
						<sas-card class="mt-3">
							<div class="embed-responsive embed-responsive-16by9 card-img-top">
								<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/iTblVtjE3HU?controls=0" title="ShareaStrength Report Intro Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
							<sas-card-footer>
								<strong>Report Overview Video</strong>
							</sas-card-footer>
						</sas-card>
					</ng-container>
					<ng-template #compareActivity>
						<sas-card>
							<sas-card-header>
								<strong class="card-title">Recent</strong>
							</sas-card-header>
							<sas-card-body [gray]="true" class="d-flex flex-column">
								<app-user-tease *ngFor="let comparison of userComparisonActivity" [user]="comparison.user" size="xs"></app-user-tease>
								<a routerLink="/me/community/recent" class="btn btn-link btn-sm p-0">Show More</a>
							</sas-card-body>
						</sas-card>
					</ng-template>
				</div>
				<ng-template #onboarding>
					<p class="mb-2"><strong>Things to try:</strong></p>
					<p>We're glad you made it, and welcome to Share a Strength! Below is a list of things to try if you find yourself needing a little direction.</p>
					<sas-card>
						<div class="list-group list-group-flush">
							<ng-container *ngFor="let item of onboardingSuggestions$|async">
								<!-- @TODO review *ngVar implementation -->
								<a class="list-group-item list-group-item-action d-flex align-items-center justify-content-between"
									[ngbTooltip]="item.content"
									container="body"
									placement="left"
									(click)="handleClick(item);"
								>
									<span>{{item.title}}</span>
									<span class="fa text-primary" [class.fa-chevron-right]="true" [class.fa-check]="false"></span>
								</a>
							</ng-container>
						</div>
					</sas-card>
				</ng-template>
				<app-footer [contained]="false"></app-footer>
			</ng-container>
		</aside>

	</div>

</ng-container>

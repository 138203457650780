import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, forkJoin, of, zip } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import ReportDataModel from '../interfaces/report-data';
import ReportDataTraitModel, {
	ReportDataTraitModelExtended
} from '../interfaces/report-data-trait';
import { ReportFlattener } from '../classes/report-flattener';
import { User } from '../store/user/user.model';
import { Trait } from '../interfaces/trait';
import { TraitService } from './trait.service';
import { UserService } from './user.service';
import { UserAssessment } from '../modules/assessment/services/assessment.service';
import { ReportResultItem } from '../modules/user/services/user.service';

export interface ReportContext {
	traits: any[];
	user: User;
	report: any;
}

/**
 * @Devnote - rework this entire thing to handle restful routing and data loading
 *
 */
@Injectable({
	providedIn: 'root'
})
export class ReportService {
	API_BASE_URL: string = environment.api_base_url;

	userId: string;

	private _user: any;
	public get user() {
		return this._user;
	}

	private _traits: any;
	public get traits() {
		return this._traits;
	}

	private _data: ReportDataModel[];
	public get data() {
		return this._data;
	}

	// private _flattenedReport: TraitAggregateModel[];
	public get flattenedReport() {
		return this.getFlattenedReportData();
	}

	constructor(
		private http: HttpClient,
		private traitService: TraitService,
		private userService: UserService
	) {}

	/**
	 * @devnote this matches the API's sort method
	 */
	sortTraits(a, b) {
		return a.dominance === b.dominance
			? a.avgResponseTime - b.avgResponseTime
			: b.dominance - a.dominance;
	}

	getReportForUser(id: string): Observable<ReportResultItem[]> {
		return this.getLastCompletedAssessment(id).pipe(
			switchMap(assessment =>
				this.getReportForAssessment(id, assessment)
			),
			catchError(e => of([]))
		);
	}

	getLastCompletedAssessment(id: string) {
		return this.http.get<any>(
			`${environment.api_base_url}/users/${id}/assessments/completed/last`
		);
	}

	getReportForAssessment(userId, assessment) {
		return zip(
			this.getLocallySortedReportForAssessment(userId, assessment),
			this.userService.getTraitSegmentForUser(userId, 'top', 25)
		).pipe(
			map(([report, traitIds]) => {
				if (traitIds.length === 25) {
					return traitIds.map(id => report.find(d => d.id === id));
				}
				return report;
			})
		);
	}

	getLocallySortedReportForAssessment(userId, assessment) {
		return this.http
			.get<any>(
				`${environment.api_base_url}/users/${userId}/assessments/${assessment.id}/report`
			)
			.pipe(
				map(
					report =>
						JSON.parse(report.data).results.sort(
							this.sortTraits
						) /** sort is redundant here... */
				)
			);
	}

	/**
	 * Updated Report Getter
	 *
	 * @Devnote use this one moving forward!!!
	 * @since 1.0.0
	 * @author LWK<lew@dankestudios.com>
	 *
	 * @usage
	 * ```typescript
	 * getReportContextForUser(12345)
	 *      .subscribe(report => {
	 *          // do some magic...
	 *      });
	 * ```
	 */
	public getReportContextForUser(
		userId: string
	): Observable<ReportDataTraitModelExtended[]> {
		return this.getReportContext(userId).pipe(
			map(({ traits, user, report }) =>
				this.getExpandedReportData(report, traits)
			)
		);
	}

	/**
	 * Get Report data with Traits and Ranks
	 *
	 * @author LWK
	 */
	public getExpandedReportData(
		report: ReportDataModel[],
		traits: Trait[]
	): ReportDataTraitModelExtended[] {
		/**
		 * @Devnote this is the correct way to compute the user's
		 * ordered strengths.
		 */
		return report
			.pop() // get the last one... and there should always be at least one when this is called...
			.data.map((item: ReportDataTraitModel) => {
				return <ReportDataTraitModelExtended>{
					...item,
					trait: traits.find(t => t.id === item.id)
				};
			})
			.sort(
				// @Devnote - below is the correct sorting algorhythm for report data
				(a, b) =>
					b.dominance - a.dominance ||
					a.avgResponseTime - b.avgResponseTime
			)
			.map((item, index) => {
				// Add Rank and Order
				item.order = index;
				item.rank = index + 1;
				return item;
			});
	}

	/**
	 * Get User Context
	 *
	 * Gets all reports history, unaltered.
	 *
	 * @since v0.0.3
	 * @author LWK
	 * @return data
	 */
	public getReportContext(
		userId: string
	): Observable<{ traits: Trait[]; user: User; report: ReportDataModel[] }> {
		return forkJoin(
			this.traitService.traits,
			this.userService.getUserData(userId),
			this._getReportForUser(userId)
		).pipe(
			map(([traits$, user$, report$]) => {
				return { traits: traits$, user: user$, report: report$ };
			})
		);
	}

	/**
	 * Get report data for user
	 * @since v0.0.3
	 * @author LWK
	 * @param null
	 * @return Promise<any>
	 */
	private _getReportForUser(
		userId: String = this.userId
	): Observable<ReportDataModel[]> {
		return this.http
			.get<ReportDataModel[]>(
				`${this.API_BASE_URL}/users/${userId}/assessments`
			)
			.pipe(map(data => (this._data = data)));
	}

	/**
	 * Get Last completed assessment for user
	 *
	 * @author LWK
	 */
	public getLatestCompletedAssessmentForUser(
		userId: string = this.userId
	): Observable<UserAssessment> {
		return this.http
			.get<any>(
				`${this.API_BASE_URL}/users/${userId}/assessments/completed/last`
			)
			.pipe(catchError(err => of(false)));
	}

	/**
	 * Get Flattened report data
	 * @todo this should be moved to a computational location for data crunching used throughout the app, or server side...
	 * @since v0.0.3
	 * @author LWK
	 * @param null
	 * @return Object Flattened Report Data
	 */
	public getFlattenedReportData(data: any = this._data): ReportFlattener {
		return new ReportFlattener(data);
	}

	/**
	 * Get Report Segments (Date, Average)
	 * @since v0.0.3
	 * @author LWK
	 * @return array Array of Report Segments
	 */
	public getReportSegments(data): any[] {
		return data.map(report =>
			Object.assign(
				{},
				{
					id: report.id,
					created_at: new Date(report.created_at),
					completed_at: new Date(report.updated_at)
				}
			)
		);
	}
}

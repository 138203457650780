import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
	selector: 'app-loading-indicator',
	templateUrl: './loading-indicator.component.html',
	styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
	@Input()
	@HostBinding('class.full-screen')
	fullScreen = true;

	@Input()
	@HostBinding('class.light')
	light = false;

	@Input()
	indicatorText: string;

	constructor() {}

	ngOnInit() {}
}

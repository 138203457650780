import { Action, createAction, props } from '@ngrx/store';
import { UserActivity, UserComparisonActivity } from './user-activity.model';

/**
 * LOAD_COMPARISONS UserActivity
 * @param userComparisonActivity UserComparisonActivity[]
 */
export const LOAD_COMPARISONS = '[UserActivity] LOAD';
export class LoadComparisons implements Action {
  readonly type = LOAD_COMPARISONS;
  constructor(public payload: UserComparisonActivity[]) {}
}

/**
 * LOG_COMPARISON UserActivity
 * @param userComparisonActivity UserComparisonActivity
 */
export const LOG_COMPARISON = '[UserActivity] LOG_COMPARISON';
export class LogComparison implements Action {
  readonly type = LOG_COMPARISON;
  constructor(public payload: UserComparisonActivity) {}
}

/**
 * CLEAR UserActivity
 */
export const CLEAR = '[UserActivity] CLEAR';
export class Clear implements Action {
  readonly type = CLEAR;
}

export type All = LogComparison | Clear | LoadComparisons;

/**
 * NgRx@12.4.0 Updates
 *
 */
export const loadActivity = createAction(
  '[UserActivity] Load',
  props<{ comparisons: UserComparisonActivity[] }>(),
);
export const logComparison = createAction(
  '[UserActivity] Log Comparison',
  props<{ comparison: UserComparisonActivity }>(),
);
export const clear = createAction('[UserActivity] Clear');

import {
	Component,
	OnInit,
	ElementRef,
	ViewChild,
	Input,
	HostBinding,
	Output,
	OnDestroy
} from '@angular/core';
import Glide from '@glidejs/glide';
import { BehaviorSubject } from 'rxjs';

export interface SliderOptions {
	type?: 'slider' | 'carousel';
	startAt?: number;
	perView?: number;
	focusAt?: number;
	gap?: number | { before: number; after: number };
	autoplay?: number;
	hoverpause?: boolean;
	keyboard?: boolean;
	bound?: number;
	swipeThreshold?: number;
	dragThreshold?: number;
	perTouch?: number;
	touchRatio?: number;
	touchAngle?: number;
	animationDuration?: number;
	rewind?: boolean;
	rewindDuration?: number;
	animationTimingFunc?: Function;
	direction?: 'ltr' | 'rtl';
	peek?: number | { before: number; after: number };
	breakpoints?: { [key: number]: Partial<SliderOptions> };
	classes?: {
		direction?: {
			ltr?: string;
			rtl?: string;
		};
		slider?: string;
		carousel?: string;
		swipeable?: string;
		dragging?: string;
		cloneSlide?: string;
		activeNav?: string;
		activeSlide?: string;
		disabledArrow?: string;
	};
	throttle?: number;
}

/**
 * Glide JS Slider
 *
 * We're not using ul > li markup here, so we'll need
 * to revisit accessibility down the line.
 *
 * @devnote When using 'carousel' type slider, all child
 * components must not contain any render blocking timeouts,
 * or the markup for that slide will not show. This has to do
 * with glide duplicating slide HTML for the first and last
 * slide to simulate a 'continuous revolution' vs. a recylcing
 * to the beginning of the slides.
 *
 * @author LWK
 */
@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
	@HostBinding('class.glide') glideClassName = true;

	@ViewChild('sliderElement', { static: true }) sliderElement: ElementRef;

	slider: any;

	defaultOptions: SliderOptions = {
		type: 'slider',
		perView: 2,
		peek: { before: 0, after: 100 },
		autoplay: 5000,
		hoverpause: true,
		bound: 2
	};

	@Input() sliderOptions: SliderOptions = {};

	@Input() verticallyCenter: boolean = false;

	public index: BehaviorSubject<number> = new BehaviorSubject(0);

	public get currentIndex() {
		return this.slider ? this.slider.index : 0;
	}

	constructor(private el: ElementRef) {}

	ngOnInit() {
		setTimeout(() => {
			this.slider = new Glide(this.el.nativeElement, {
				...this.defaultOptions,
				...this.sliderOptions
			});
			this.slider.on('move', () => this.index.next(this.slider.index));
			this.slider.mount();
		});
	}

	ngOnDestroy() {
		this.slider.destroy();
	}

	rebuild() {
		this.slider.destroy();
		this.slider = new Glide(this.el.nativeElement, {
			...this.defaultOptions,
			...this.sliderOptions
		});
		this.slider.mount();
	}

	destroy() {
		this.slider.destroy();
	}

	update() {
		this.slider.update();
	}

	next() {
		this.slider.go('>');
	}

	prev() {
		this.slider.go('<');
	}

	go(index) {
		this.slider.go(`=${index}`);
	}
}

import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientAuthInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		/**
		 * Updated for digital ocean testing
		 * @devnote in currren production setup we are
		 * manually setting this. CORS should handle our
		 * auth so we may be able to remove this moving forward.
		 */
		if (null !== environment.client_token) {
			return next.handle(
				req.clone({
					setHeaders: {
						'Client-Token': environment.client_token,
					},
				})
			);
		} else {
			return next.handle(req);
		}
	}
}

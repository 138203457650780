import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ObservableLike, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Survey } from '../interfaces/survey';
import { ToastService } from '../modules/global/toast/toast.service';
import { SurveyModalService } from '../modules/testing/components/survey-modal/survey-modal.service';
import { UserPreferencesService } from './user-preferences.service';

export const SURVEY_MAP = {
	ASSESSMENT: 1
};

export const SURVEY_DESCRIPTION_MAP = {
	1: 'Give feedback on the onbaording process, the assessment experience, and comparing with other users.'
};

export const SURVEY_REQUIRED_QUESTION_IDS_MAP = {
	1: [1, 2, 3, 4, 5, 7]
};

export const SURVEY_PREFERENCE_MAP = {
	1: 'has_seen_alpha_post_assessment_survey'
};

export const QUESTION_TYPES = {
	RATING: 'rating',
	MULTIPLE_SELECT: 'multiple_select',
	TEXT: 'text'
};

@Injectable({
	providedIn: 'root'
})
export class SurveyService {
	private _loaded = false;
	private _surveys: BehaviorSubject<Survey[]> = new BehaviorSubject([]);
	public get surveys(): BehaviorSubject<Survey[]> {
		return this._surveys;
	}

	constructor(
		private http: HttpClient,
		private surveyModalService: SurveyModalService,
		private userPreferencesService: UserPreferencesService
	) {}

	public async showSurvey(surveyId: number) {
		const userPreference = SURVEY_PREFERENCE_MAP[surveyId];
		if (userPreference) {
			// Update store
			const preferencesUpdated = await this.userPreferencesService
				.updateUserPreference(userPreference, true)
				.toPromise();
		}
		return this.surveyModalService.showModalForSurvey(surveyId);
	}

	/**
	 * Check if a Survey should be shown. Show it if it should.
	 * @param surveyId
	 */
	public async shouldShowSurvey(surveyId: number): Promise<boolean> {
		/**
		 * For now, we're checking that all questions are answered. We
		 * need to add a 'decline' or 'ask me later' option and
		 * consider the user's response here.
		 */
		const surveys = await this.surveys.toPromise();
		const survey = surveys.find(_survey => _survey.id === surveyId);
		return survey && survey.questions.some(q => q.response === null);
	}

	/**
	 * Load all surveys and push them onto our observable.
	 */
	public async init(force = false): Promise<void> {
		if (this._loaded && !force) {
			console.log('SurveyService already initialized');
			return;
		}
		const surveys = await this._loadSurveys().toPromise();
		this.surveys.next(this.setupSurveyQuestions(surveys));
		this._loaded = true;
		console.log('SurveyService initialized');
	}

	/**
	 * Get all active surveys from the API.
	 * Returns all surveys with questions and
	 * the user's responses.
	 */
	private _loadSurveys(): Observable<Survey[]> {
		return this.http.get<Survey[]>(
			`${environment.api_base_url}/me/surveys`
		);
	}

	/**
	 * Get single survey with user responses.
	 * @param number surveyId
	 */
	public getSurvey(surveyId: number): Observable<Survey> {
		return this.http.get<Survey>(
			`${environment.api_base_url}/me/surveys/${surveyId}`
		);
	}

	/**
	 * Post a survey question response on behalf of the
	 * current authenticated user.
	 */
	public storeResponse(
		surveyId: number,
		questionId: number,
		response: any
	): Observable<any> {
		return this.http
			.post<any>(
				`${environment.api_base_url}/me/surveys/${surveyId}/${questionId}`,
				{ response }
			)
			.pipe(
				switchMap(res => {
					if (res) {
						// Update our local surveys
						return this.init();
					}
					return of(res);
				}),
				map(res => !!res)
			);
	}

	/**
	 * Post all responses for a survey on behalf of the
	 * current authenticated user.
	 * @devnote API endpoint does not exist
	 */
	public storeResponses(
		surveyId: number,
		responses: { question: number; response: any }[]
	): Observable<any> {
		const userPreference = SURVEY_PREFERENCE_MAP[surveyId];
		console.log('User Preference', userPreference);
		return this.http.post<any>(
			`${environment.api_base_url}/me/surveys/${surveyId}`,
			responses
		);
	}

	/**
	 * Check a survey for completeness
	 * Requires definition of required fields in the
	 * surevey required fields map.
	 */
	public checkSurveyForCompleteness(survey: Survey): boolean {
		return !survey.questions
			.filter(q => this.checkSurveyQuestionIsRequired(survey, q))
			.some(q => q.response === null);
	}

	/**
	 * Checks local required questions map
	 * to determine whether or not a question
	 * is required.
	 */
	public checkSurveyQuestionIsRequired(survey, question): boolean {
		return (
			(SURVEY_REQUIRED_QUESTION_IDS_MAP[survey.id] || []).indexOf(
				question.id
			) > -1
		);
	}

	/**
	 * Converts response property to _array_ where
	 * question type is **MULTIPLE_SELECT**
	 */
	public setupSurveyQuestions(surveys: Survey[]): Survey[] {
		return surveys.map(survey => {
			survey.questions.forEach(q => {
				if (
					q.type === QUESTION_TYPES.MULTIPLE_SELECT &&
					typeof q.options == 'string'
				) {
					q.options = JSON.parse(q.options);
					q.response = q.response ? JSON.parse(q.response) : [];
				}
			});
			return survey;
		});
	}
}

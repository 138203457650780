<div class="modal modal-trait fade d-block show" tabindex="-1" role="dialog" (click)="close($event)">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-body">
				<app-slider #slider [sliderOptions]="{ peek: 0, perView: 1, autoplay: false }" [verticallyCenter]="true">
					<app-slide *ngFor="let slide of slides; let i = index" [id]="slide.slug">
						<div class="py-5 text-center">
							<div class="image-surround pb-5">
								<img *ngIf="slide.image; else logo" [src]="slide.image"/>
								<ng-template #logo>
									<sas-logo *ngIf="i===0" width="180px" height="60px" fill="#1E263F" [v3]="true"></sas-logo>
								</ng-template>
							</div>
							<p class="h4 slide-title" [innerHTML]="slide.title"></p>
							<p class="slide-content" [innerHTML]="slide.content"></p>
						</div>
					</app-slide>
				</app-slider>
				<nav class="slider-nav nav justify-content-center">
					<button *ngFor="let slide of slides; let i = index" [class.btn-outline-secondary]="(slider.index|async) !== i" [class.btn-secondary]="(slider.index|async) === i" class="btn btn-sm btn-bullet rounded-circle p-1 mx-1" (click)="slider.go(i)">
						<span class="sr-only">Go to slide {{i+1}}</span>
					</button>
				</nav>
			</div>
			<div class="modal-footer justify-content-center">
				<button (click)="handleContinueClick()" class="btn btn-link btn-sm">Continue</button>
			</div>
		</div>
    </div>
</div>
<div class="modal-backdrop fade show" (click)="close()"></div>

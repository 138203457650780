import { Component, OnInit, ViewChild, Input } from '@angular/core';
import {
	NgbModal,
	ModalDismissReasons,
	NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/store/user/user.model';
import { UserService } from 'src/app/services/user.service';
import { InviteFriendModalService } from './invite-friend-modal.service';

@Component({
	selector: 'sas-invite-friend-modal',
	templateUrl: './invite-friend-modal.component.html',
	styleUrls: ['./invite-friend-modal.component.scss']
})
export class InviteFriendModalComponent implements OnInit {
	@Input()
	user: User;

	@ViewChild('modalContent', { static: false })
	modalContent: any;

	modalRef: any;

	closeResult: string;

	emailAddress: string = '';

	messageSent = false;

	get canSend(): boolean {
		const input = <HTMLInputElement>(
			window.document.getElementById('recipient')
		);
		return input ? input.value !== '' && input.validity.valid : false;
	}

	constructor(
		public inviteFriendModalService: InviteFriendModalService,
		private modalService: NgbModal,
		private userService: UserService
	) {}

	ngOnInit() {
		// Listen to service for remote opening
		this.inviteFriendModalService.showModalStream.subscribe(() => {
			console.log(this.user, this.modalContent);
			if (!this.modalRef) {
				this.openModal(this.modalContent);
			}
		});
	}

	openModal(content: any) {
		this.modalRef = this.modalService
			.open(content, {
				ariaLabelledBy: 'modal-invite-friend',
				windowClass: 'modal-invite-friend',
				// size: 'md',
				centered: true
			})
			.result.then(
				result => {
					this.closeResult = `Closed with: ${result}`;
				},
				reason => {
					this.closeResult = `Dismissed ${this.getDismissReason(
						reason
					)}`;
				}
			)
			.then(() => this.reset());
	}

	getDismissReason(reason: ModalDismissReasons) {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	sendInvite() {
		this.userService.inviteFriend(this.emailAddress).subscribe(res => {
			// Alert here
			this.messageSent = true;
			// this.modalService.dismissAll();
		});
	}

	/**
	 * Reset the modal context
	 *
	 */
	reset(): void {
		this.emailAddress = null;
		this.messageSent = false;
		this.modalRef = null;
	}
}

import {
	Component,
	OnInit,
	Input,
	HostBinding,
	ElementRef
} from '@angular/core';

@Component({
	selector: 'sas-card-header',
	template: '<ng-content></ng-content>',
	styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {
	@Input() classes: string[] = [];
	@Input() attributes: string[];

	@Input('class')
	@HostBinding('class')
	className: string;
	@HostBinding('class.card-header') classCardHeader = true;
	@HostBinding('class.sas-card-header') classSasCardHeader = true;

	@Input('id')
	@HostBinding('id')
	id;

	constructor(private el: ElementRef) {}

	ngOnInit() {
		this.className += ` ${this.classes.join(' ')}`;
	}
}

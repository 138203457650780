import { Component, OnInit } from "@angular/core";
import { Trait } from "src/app/interfaces/trait";
import { ActivatedRoute, Data } from "@angular/router";
import { Observable } from "rxjs";
import { take, map, switchMap, tap, share } from "rxjs/operators";

@Component({
    selector: "app-trait-feed",
    templateUrl: "./trait-feed.component.html",
    styleUrls: ["./trait-feed.component.scss"]
})
export class TraitFeedComponent implements OnInit {

    trait$: Observable<Trait> = this.route.data.pipe(map((d: Data) => d.trait));

    constructor(private route: ActivatedRoute) {}

    ngOnInit() { }
    
}

<div>
	<ul *ngIf="showLinks" class="nav nav--footer flex-row">
		<li class="nav-item px-0 mr-2" *ngFor="let link of links">
			<a class="nav-link p-0" [routerLink]="link.url" [innerHTML]="link.text"></a>
		</li>
		<li class="nav-item px-0">
			<a routerLink="#more" class="nav-link p-0">More</a>
		</li>
	</ul>
	<p class="copyright text-muted">
		<!-- A ten minute assessment can transform the relationships you make for a lifetime.<br> -->
		&copy; {{ currentYear }} ShareaStrength Inc. <!-- All rights reserved. -->
	</p>
</div>

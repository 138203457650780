<div
	*ngIf="$survey | async as survey"
	class="survey__questions"
	[id]="'survey-' + survey.id"
>
	<ng-container *ngFor="let q of survey.questions; let i = index">
		<app-survey-question
			*ngIf="shouldShowQuestion(q, survey)"
			[survey]="survey"
			[question]="q"
		></app-survey-question>
	</ng-container>
</div>

<app-navigation
	[navbarColorLight]="false"
	#navigation
	id="app-navigation"
></app-navigation>
<router-outlet></router-outlet>
<sas-toast-outlet></sas-toast-outlet>
<sas-traits-modal-outlet></sas-traits-modal-outlet>
<sas-invite-friend-modal
	*ngIf="userService.user|async as user"
	[user]="user"
></sas-invite-friend-modal>
<app-user-onboarding-modal
	*ngIf="userOnboardingModalService.showModal"
></app-user-onboarding-modal>
<app-survey-modal *ngIf="surveyModalService.showModal"></app-survey-modal>
<app-help-form *ngIf="helpFormService.show"></app-help-form>
<app-feedback-fab *ngIf="userService.userFromStore|async as user"></app-feedback-fab>

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { ResponseInterceptor } from './response-interceptor';
import { ClientAuthInterceptor } from './client-auth-interceptor';
/**
 * Provides the HttpInterceptors needed.
 * 
 * DEVNOTE: Consider moving this to a separate file that imports AuthInterceptor and other HttpInterceptors.
 */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ClientAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
];
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	map,
	catchError,
	tap,
	switchMap,
	takeUntil,
	endWith,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { User } from '../store/user/user.model';
import { ToastService } from '../modules/global/toast/toast.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';
import { selectUser } from '../store';

export const REQUIRED_PROFILE_DATA_POINTS: string[] = [
	'dob',
	'sex',
	'occupation_meta_id',
	'occupation_id',
	'profession',
	'marital_status',
	// 'city',
	'zip',
];

export interface PaginatedData {
	current_page: number;
	first_page_url: string;
	from: number;
	last_page: number;
	last_page_url: string;
	next_page_url: string;
	path: string;
	per_page: number;
	prev_page_url: string;
	to: number;
	total: number;
	data: any[];
}

interface PaginatedReferrals extends PaginatedData {
	data: {
		referrer: string;
		email: string;
		response: 'accepted' | 'rejected' | 'none' | string;
		created_at: string;
		signup_url: string;
	}[];
}

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public get loggedOutNotifier() {
		return this.authService.logoutEmitter;
	}

	/**
	 * User getter that unsubscribes on logout
	 *
	 * @author LWK
	 */
	private _user: Observable<User> = this.store.select(selectUser).pipe(
		takeUntil(this.authService.logoutEmitter),
		// Send _null_ when we logout
		endWith(null)
	);
	get user(): Observable<User> {
		return this._user;
	}

	/**
	 * User getter that doesn't unsibscribe
	 *
	 * @author LWK
	 */
	get userFromStore(): Observable<User> {
		return this.store.select(selectUser);
	}

	constructor(
		private http: HttpClient,
		private toastService: ToastService,
		private store: Store,
		private authService: AuthenticationService
	) {}

	/**
	 * Get All Users
	 *
	 * @since v0.0.3
	 * @author LWK
	 */
	getUsers(): Observable<User[]> {
		return this.http.get<any>(`${environment.api_base_url}/users`);
	}

	/**
	 * Let's get the friends of a user by id
	 *
	 * @author LWK <lew@dankestudios.com>
	 * @since 2.0.0
	 * @param id ID of the user
	 */
	getFriendsForUser(id: string): Observable<User[]> {
		return this.http.get<User[]>(
			`${environment.api_base_url}/users/${id}/friends`
		);
	}

	/**
	 * Get data for user with id
	 *
	 * @since v0.0.3
	 * @author LWK
	 * @param id string
	 * @return Observable<any>
	 */
	getUserData(id: string): Observable<any> {
		return this.http.get<any>(`${environment.api_base_url}/users/${id}`);
	}

	/**
	 * Get Order Trait Segment for User
	 *
	 * @Devnote get top|bottom count traits for
	 * the specified user.
	 * @author LWK
	 */
	getTraitSegmentForUser(
		id: string,
		pole: 'top' | 'bottom' = 'top',
		count = 5
	): Observable<number[]> {
		return this.http
			.get<number[]>(
				`${environment.api_base_url}/users/${id}/traits/${pole}/${count}`
			)
			.pipe(catchError((e) => of([])));
	}

	/**
	 * Invite a friend with given email
	 *
	 * @Devnote we need to check if the user has already
	 * been invited before we go spamming a user
	 * with residual invitations.
	 *
	 * @author LWK
	 */
	inviteFriend(email: string): Observable<string> {
		return this.validateInviteEmail(email).pipe(
			switchMap(
				(canSend) => (canSend ? this.createReferral(email) : of('')) // Notify user that they've been invited...
			)
		);
	}

	/**
	 * Resent Invite for User
	 * @param Number referral ID
	 */
	resendInvite(referralId: number): Observable<boolean> {
		return this.http
			.post<string>(`${environment.api_base_url}/users/referral`, {
				referral: referralId,
			})
			.pipe(
				//  @todo turn these into a standard response handler
				map((res) => {
					return res === 'true';
				}),
				catchError((E) => of(false))
			);
	}
	/**
	 * Get Referrals for User
	 *
	 * @param email
	 */
	getReferrals(): Observable<PaginatedReferrals> {
		return this.http.get<PaginatedReferrals>(
			`${environment.api_base_url}/me/referrals`
		);
	}

	/**
	 * Create the referral
	 *
	 * @Devnote Creates the referral and action, dispatches
	 * email to recipient.
	 */
	createReferral(email: string): Observable<string> {
		return this.http
			.put<string>(`${environment.api_base_url}/users/referral`, {
				email,
			})
			.pipe(
				tap((inviteUrl) => {
					if (inviteUrl) {
						this.toastService.queueToast({
							title: 'Invite sent!',
							content: `We just sent an invite to ${email}. Check back soon to start comparing!`,
						});
					}
				})
			);
	}

	/**
	 * Validate a referral email
	 *
	 * Check to see if a user has already been invited
	 * given a specific email. Sends back true if the
	 * invite can be sent.
	 */
	validateInviteEmail(email: string): Observable<boolean> {
		return this.http
			.post<any>(`${environment.api_base_url}/users/referral/validate`, {
				email,
			})
			.pipe(catchError((referralNotFound) => of(true)));
	}

	/**
	 * User Profile Completion Check
	 *
	 * This is used during the login process to
	 * determine whether or not we need to show
	 * the user the onboarding screens.
	 *
	 * @author LWK
	 */
	userHasCompletedAllRequiredDemographics(user: User): boolean {
		return !REQUIRED_PROFILE_DATA_POINTS.some(
			(key) => user.profile[key] === null
		);
	}

	/**
	 * Get Generation from User DOB
	 *
	 * @devnote is there a better plave to put
	 * utility methods like this?
	 *
	 * @author LWK
	 */
	getUserGeneration(dob: string): string {
		const birthYear = new Date(dob).getFullYear();
		if (birthYear > 2000) {
			return 'Centennial (Generation Z)';
		} else if (birthYear > 1980) {
			return 'Millenial (Generation Y)';
		} else if (birthYear > 1960) {
			return 'Generation X';
		} else if (birthYear > 1940) {
			return 'Baby Boomer';
		} else if (birthYear > 1920) {
			return 'Traditionalist (The Silent Generation)';
		} else {
			return 'The Greatest Generation';
		}
	}

	/**
	 * Get Pronour for User
	 *
	 * @author LWK
	 */
	getUserPronoun(sex: string, possessive?: boolean): string {
		switch (sex) {
			case 'male':
				return possessive ? 'his' : 'he';
			case 'female':
				return possessive ? 'her' : 'she';
			default:
				return possessive ? 'their' : 'they';
		}
	}
}

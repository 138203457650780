import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-state.model';
import { User } from '../store/user/user.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';

import * as Sentry from '@sentry/angular';
import { selectUser } from '../store';

/**
 * Auth Guard Check
 *
 * Confirms the user is logged in. Redirects to login
 * page if not.
 *
 * @author LWK
 */
@Injectable({
	providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
	constructor(
		private store: Store,
		private auth: AuthenticationService,
		private router: Router
	) {}

	private userIsInStore = false;
	private sentryUserIsIdentified = false;

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		return this.store.select(selectUser).pipe(
			tap((user: User) => {
				if (!this.sentryUserIsIdentified && user) {
					this.auth.setSentryUser(user);
					this.sentryUserIsIdentified = true;
				}
			}),
			map((user: User) => (user ? true : false)),
			switchMap((userIsInStore: boolean) => {
				if (userIsInStore) {
					this.userIsInStore = true;
					return this.auth.validateIsActivelyAuthenticated();
				}
				this.userIsInStore = false;
				// return false because there's no user in the store.
				return of(false);
			}),
			switchMap((userIsAuthenticated: boolean) => {
				if (this.userIsInStore && !userIsAuthenticated) {
					return this.auth.doLogOut().pipe(
						switchMap(() =>
							this.router.navigateByUrl('auth/login')
						),
						// Return false because we _should not_ activate.
						switchMap((navigateSuccess: boolean) => of(false))
					);
				}
				console.log(
					`User is ${userIsAuthenticated ? '' : 'not'} authenticated`
				);
				return of(this.userIsInStore);
			})
		);
	}
}

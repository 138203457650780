import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';
import { TraitsModalService } from 'src/app/components/traits-modal/traits-modal.service';

@Component({
	selector: 'sas-traits-modal',
	templateUrl: './traits-modal.component.html',
	styleUrls: ['./traits-modal.component.scss']
})
export class TraitsModalComponent implements OnInit {
	@Input('traits') traits: Trait[];

	@Input('traitIds') traitIds: number[];

	@Input('showSearch') showSearch = true;

	@Input('search') search = '';

	@Output() dismiss: EventEmitter<void> = new EventEmitter();

	show = false;

	get filteredTraits() {
		if (this.search) {
			return this.traits.filter(
				item =>
					item.name_general
						.toLowerCase()
						.indexOf(this.search.toLowerCase()) > -1
			);
		}
		return this.traits;
	}

	constructor(private traitsModalService: TraitsModalService) {}

	ngOnInit() {
		console.log(this.traits);
	}

	close() {
		this.dismiss.emit();
	}

	showTraitModal(id: number) {
		this.traitsModalService.showTraitDetailModalForTrait(id);
		this.close();
	}

	handleDialogClick(event) {
		if (event.target.getAttribute('role') === 'dialog') {
			this.close();
		}
	}
}

import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { UserService } from '../services/user.service';
import { switchMap, map, tap, filter, take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app-state.model';
import { UserComparisonObservable } from '../classes/user-comparison-observable';
import { selectUser } from '../store';

/**
 * Checks that user has completed all required profile points.
 * Redirects to /onboarding/welcome if not.
 *
 * @author LWK
 */
@Injectable({
	providedIn: 'root'
})
export class UserReportCheckService implements CanActivate {
	constructor(
		private router: Router,
		private store: Store,
		private userService: UserService
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.store.select(selectUser).pipe(
			tap(user => {
				if (!environment.production) {
					console.log(
						'UserReportCheckService',
						'CanActivate',
						'user',
						user.profile
					);
				}
			}),
			map(
				user => true
				/**
				 * @devnote Disabling insights for beta
				 */
				// user
				// 	? this.userService.userHasCompletedAllRequiredDemographics(
				// 			user
				// 	  )
				// 	: false
			),
			tap(complete => {
				if (!complete) {
					console.warn('ReRoute', '/onboarding/welcome');
					this.router.navigate(['/', 'onboarding', 'welcome']);
				}
				console.log(
					'Report check',
					'User has completed all required profile points'
				);
			})
		);
	}
}

import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild,
	RouterStateSnapshot,
} from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class SeoGuardService implements CanActivate, CanActivateChild {
	public pageTitle = '';

	constructor(private title: Title, private meta: Meta) {}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		return this._updateRouteTitle(route, state);
	}

	public canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		return this._updateRouteTitle(route, state);
	}

	public setPageTitle(title: string) {
		this.pageTitle = title;
		this.title.setTitle(this.pageTitle);
	}

	/**
	 * Update page meta on route transition
	 * @autor LWK
	 */
	private _updateRouteTitle(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (route.data.title) {
			console.log('Route title set to ' + route.data.title);
			this.setPageTitle(route.data.title);
		}
		return true;
	}
}

import {
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
	AfterViewInit,
} from '@angular/core';

@Component({
	selector: 'app-report-page',
	templateUrl: './report-page.component.html',
	styleUrls: ['./report-page.component.scss'],
})
export class ReportPageComponent implements OnInit {
	@Input() headerText: string;

	@Input() footerText: string;

	@Input() variant: string;

	@HostBinding('class') className = 'page';

	get currentYear() {
		return new Date().getFullYear();
	}

	constructor(private el: ElementRef) {}

	ngOnInit(): void {
		if (this.variant) {
			this.className += ` page--${this.variant}`;
		}
		if (!this.footerText) {
			setTimeout(() => {
				let index = Array.from(
					this.el.nativeElement.parentElement.children
				).indexOf(this.el.nativeElement);
				index++;
				this.footerText = `&copy; ${this.currentYear} ShareaStrength LLC.<br/>Page ${index}`;
			}, 250);
		}
	}
}

import { Component, OnInit } from '@angular/core';
import { HelpFormService } from '../help-form/help-form.service';

@Component({
	selector: 'app-feedback-fab',
	styleUrls: ['./feedback-fab.component.scss'],
	template: `
		<button
			class="btn btn-secondary btn--fab btn--fab-feedback"
			(click)="helpFormService.showModal()"
			aria-label="Give feedback or request help"
		>
			<i class="fas fa-bullhorn"></i>
			<span class="d-none d-lg-block">Help/Feedback</span>
		</button>
	`
})
export class FeedbackFabComponent {
	constructor(public helpFormService: HelpFormService) {}
}

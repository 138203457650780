<div class="search-form">
	<input #searchInput
		type="text"
		class="form-control search-input"
		name="search"
		placeholder="Search Share a Strength"
		aria-label="Search Share a Strength"
		aria-describedby="search-addon"
		[class.open]="searchFormOpen"
		[(ngModel)]="query"
		(selectItem)="handleSelection($event)"
		[ngbTypeahead]="search"
		[inputFormatter]="formatResult"
		[resultTemplate]="resultTemplate"/>
	<button class="search-toggle" (click)="focusInput()">
		<span class="fa fa-search"></span>
		<span class="sr-only">Show Search</span>
	</button>
</div>

<ng-template #resultTemplate let-user="result" let-term="term">
	<div class="result-item result-item-dark d-flex align-items-center">
		<user-image
			[class.mr-2]="true"
			[user]="user"
			[small]="true"
		></user-image>
		<ngb-highlight
			[result]="user.profile?.name"
			[term]="term"
		></ngb-highlight>
	</div>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Trait } from '../interfaces/trait';
import { Observable, of } from 'rxjs';
import { map, share, switchMap, tap, catchError, filter } from 'rxjs/operators';

/**
 * Trait Information Service
 *
 * @author LWK <lew@dankestudios.com>
 */
@Injectable({
	providedIn: 'root'
})
export class TraitService {
	/**
	 * Let's cache the traits locally on the first request
	 *
	 * Note: it'd be nice to move this into the store
	 *
	 * @var Trait[]
	 */
	private _traits: Trait[];
	public get traits(): Observable<Trait[]> {
		if (this._traits) {
			return of(this._traits).pipe(share());
		} else {
			return this.getTraits().pipe(
				switchMap(traits => {
					this._traits = traits;
					return of(this._traits);
				})
			);
		}
	}

	constructor(private http: HttpClient) {}

	/**
	 * Get all traits
	 *
	 * Note: This method should no be called directly. Use the getter `traits`
	 * to properly retrieve the data set. We may want to add the ability to
	 * request only a part of the standard trait object.
	 *
	 * @author LWK <lew@dankestudios.com>
	 *
	 * @see TraitService.traits
	 */
	private getTraits(): Observable<Trait[]> {
		return this.http
			.get<Trait[]>(`${environment.api_base_url}/traits`)
			.pipe(
				filter(traits => traits !== null),
				map(traits => this._cleanupTraitContent(traits))
			);
	}

	/**
	 * Get a single trait
	 *
	 * Note: this will automatically retrieve all traits if
	 * they have not yet been loaded.
	 *
	 * @author LWK <lew@dankestudios.com>
	 *
	 * @param id traitId
	 */
	getTrait(id: number) {
		return this.traits.pipe(
			map(traits => traits.find(trait => trait.id === +id))
		);
	}

	/**
	 * Get Trait to trait Comparison
	 *
	 * @stubbed
	 * Get the strategy content for `fromTraitId`
	 * to `toTraitId`.
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	getTraitToTraitStrategy(
		fromTraitId: number,
		toTraitId: number
	): Observable<any> {
		const exampleContent =
			'DUMMY CONTENT Pipe Dreamers are optimistic, confident and committed to charging ahead on things they are passionate about. Your Lion\'s desire to influence change that makes a difference offers a quality check step to the Pipe Dreamer. By asking thoughtful questions, you help them identify potential obstacles in their path. "You seem convinced that our peers will love this idea. I\'d be happy to help you think through possible concerns that the group may have before you share this..." "Elena, I love your idea about creating a shared document of hot vacation spots. How do you plan to get our friend group to commit to adding their experiences after a trip?" These questions help the Pipe Dreamer proactively plan to ensure successful implementation of his/her great idea. As the Lion, of course you will honor your duty and participate if you make the commitment!';
		return this.http
			.get<{ from_trait: number; to_trait: number; content: string }>(
				`${environment.api_base_url}/traits/${fromTraitId}/${toTraitId}`
			)
			.pipe(
				map(traitToTraitStrategy =>
					traitToTraitStrategy
						? traitToTraitStrategy.content
						: exampleContent
				)
			);
	}

	/**
	 * Cleanup unescaped trait content
	 *
	 * @author LWK
	 */
	_cleanupTraitContent(traits: Trait[]): Trait[] {
		if (traits === null || traits === undefined) {
			return [];
		}
		console.log(traits);
		return traits.map(trait => {
			['blindspot', 'strategy', 'sweetspot', 'quote', 'haiku'].forEach(
				key => {
					trait[key].content = trait[key].content.replace(
						/\\"/g,
						'"'
					);
				}
			);
			return trait;
		});
	}
}

import { Action, createAction, props } from '@ngrx/store';
import { Trait } from 'src/app/interfaces/trait';
import { User } from './user.model';

/**
 * LOGIN User
 * @param Object User
 */
export const LOGIN = '[User] LOGIN';
export class Login implements Action {
  readonly type = LOGIN;
  constructor(public payload: User) {}
}

/**
 * LOAD_TRAITS Trait[]
 * @param Array Trait
 */
export const LOAD_TRAITS = '[User] LOAD_TRAITS';
export class LoadTraits implements Action {
  readonly type = LOAD_TRAITS;
  constructor(public payload: number[]) {}
}

/**
 * REFRESH User
 * @param Object User
 */
export const REFRESH = 'User REFRESH';
export class Refresh implements Action {
  readonly type = REFRESH;
  constructor(public payload: Partial<User>) {}
}

/**
 * LOGOUT User
 */
export const LOGOUT = '[User] LOGOUT';
export class Logout implements Action {
  readonly type = LOGOUT;
  constructor() {}
}

export type All = Login | LoadTraits | Refresh | Logout;

/**
 * NgRx@12.4.0 Updates
 *
 */
export const login = createAction('[User] Login', props<User>());
export const loadTraits = createAction('[User] Load Traits', props<{ traitIds: number[] }>());
export const refresh = createAction('[User] Refresh', props<Partial<User>>());
export const logout = createAction('[User] Logout');

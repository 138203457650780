import {
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from '../modules/authentication/services/authentication.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	constructor(private auth: AuthenticationService, private router: Router) {}
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					if (event.body.response == 'Unauthorized.') {
						this.auth
							.doLogOut()
							.pipe(
								first(),
								switchMap(_ =>
									this.router.navigateByUrl('/auth/login')
								)
							)
							.subscribe();
					}
				}
			}),
			map(event => {
				if (event instanceof HttpResponse) {
					/**
					 * Should this be && or ||??? Having an issue with firefox
					 * where the API is returning a 401 but it's being interpreted
					 * as a 200...
					 * Updated 08.19.2020
					 * @author LWK
					 */
					if (event.body.status !== 200 || event.status !== 200) {
						throw new Error(event.body.response);
					}
					// Only rewrite API specific responses
					if (event.body.response !== undefined) {
						event = event.clone({
							body: event.body.response
						});
					}
				}
				return event;
			})
		);
	}
}

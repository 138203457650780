import { createReducer, on } from '@ngrx/store';
import { Trait } from 'src/app/interfaces/trait';
import * as UserActions from './user.actions';
import { login, loadTraits, refresh, logout } from './user.actions';
import { User } from './user.model';

export type Action = UserActions.All;

const defaultState: User = null;

// Reducer
export function UserReducer(state: User = defaultState, action: Action) {
	// console.log('UserReducer() => (state, action)', state, action);

	switch (action.type) {
		case UserActions.LOGIN: {
			return action.payload;
		}

		case UserActions.LOAD_TRAITS: {
			return Object.assign(
				{},
				{
					...state,
					orderedTraitIds: action.payload
				}
			);
		}

		case UserActions.REFRESH: {
			/**
			 * Since tokens are only issued at login,
			 * it makes sense that we might want to
			 * hold onto the authentication token.
			 * ONLY the login method returns a user
			 * profile with an auth token.
			 *
			 * <austin@farmer.codes>
			 *
			 * Note: we need to look into deep copy issues from object.assign.
			 * The has nested objects that might cause issues.
			 *
			 * <lew@dankestudios.com>
			 */
			return Object.assign(
				{},
				{
					...state
				},
				{
					...action.payload
				},
				{
					preferences: {
						...state.preferences,
						...(action.payload.preferences || {})
					},
					profile: {
						...state.profile,
						...(action.payload.profile || {})
					},
					orderedTraitIds: {
						...state.orderedTraitIds,
						...(action.payload.orderedTraitIds || {})
					}
				},
				{ token: state.token, token_expiration: state.token_expiration } // Add token expiration
			);
		}

		case UserActions.LOGOUT: {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}

/**
 * NgRx@12.4.0 Updates
 *
 */
const initialState: User = null;

const handleLogin = (state: User, user: User): User => {
	return user;
};

const handleLoadTraits = (
	state: User,
	payload: { traitIds: number[] }
): User => {
	return {
		...state,
		orderedTraitIds: payload.traitIds
	};
};

const handleRefresh = (state: User, user: Partial<User>) => {
	return {
		...state,
		...user,
		preferences: {
			...state.preferences,
			...(user.preferences || {})
		},
		profile: {
			...state.profile,
			...(user.profile || {})
		},
		orderedTraitIds: {
			...state.orderedTraitIds,
			...(user.orderedTraitIds || {})
		},
		/**
		 * Token is obly returned by the login method and
		 * needs to be persisted throughout state updates.
		 */
		token: state.token,
		token_expiration: state.token_expiration
	};
};

const handleLogout = (state: User) => {
	return null;
};

// tslint:disable-next-line:variable-name
const _userReducer = createReducer(
	initialState,
	on(login, handleLogin),
	on(loadTraits, handleLoadTraits),
	on(refresh, handleRefresh),
	on(logout, handleLogout)
);

export function userReducer(state, action) {
	return _userReducer(state, action);
}

<sas-card>
	<sas-card-header>
		<strong>Share your report</strong>
	</sas-card-header>
	<sas-card-body [gray]="true">
		<ul class="nav nav--share">
			<li [class]="'nav-item ' + ('nav-item--' + option.title|lowercase)" *ngFor="let option of shareOptions">
				<a class="nav-link" target="_blank" [href]="option.href" (click)="option.handler ? option.handler($event) : noop()">
					<i [class]="option.icon"></i>
					<span class="nav-link-network">{{option.title}}</span>
				</a>
			</li>
		</ul>
	</sas-card-body>
</sas-card>

import { Directive, Input, ElementRef, OnInit, OnChanges } from '@angular/core';

@Directive({
  selector: '[appSasIcon]',
})
export class SasIconDirective implements OnInit, OnChanges {
  @Input('appSasIcon') appSasIcon: string;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.el.nativeElement.classList.add('icon');
    this.el.nativeElement.style.height = '100%';
    this.el.nativeElement.style.width = '100%';
    this.el.nativeElement.innerHTML = `<use xlink:href="assets/svg/symbol-defs.svg#icon-${this.appSasIcon}"></use>`;
  }

  ngOnChanges() {
    this.el.nativeElement.innerHTML = `<use xlink:href="assets/svg/symbol-defs.svg#icon-${this.appSasIcon}"></use>`;
  }
}

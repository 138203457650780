import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TraitsComponent } from "./containers/traits/traits.component";
import { TraitDetailComponent } from "./containers/trait-detail/trait-detail.component";
import { TraitFeedComponent } from "./components/trait-feed/trait-feed.component";
import { TraitDetailsComponent } from "./components/trait-details/trait-details.component";
import { TraitFriendsComponent } from "./components/trait-friends/trait-friends.component";
import { TraitResolverService } from "./resolvers/trait-resolver.service";

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "",
                component: TraitsComponent
            },
            {
                path: ":traitId",
                component: TraitDetailComponent,
                resolve: {
                    trait: TraitResolverService
                },
                children: [
                    {
                        path: "",
                        component: TraitFeedComponent
                    },
                    {
                        path: "details",
                        component: TraitDetailsComponent
                    },
                    {
                        path: "friends",
                        component: TraitFriendsComponent
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TraitsRoutingModule {}

import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/modules/global/toast/toast.service';
import { ToastConfig } from 'src/app/modules/global/toast/toast/toast.component';

@Injectable({
	providedIn: 'root'
})
export class SurveyModalService {
	private _showModal = false;
	get showModal() {
		return this._showModal;
	}
	set showModal(show: boolean) {
		this._showModal = show;
	}

	private _surveyId: number = null;
	get surveyId() {
		return this._surveyId;
	}
	set surveyId(id: number) {
		this._surveyId = id;
	}

	constructor(private toastService: ToastService) {}

	public showModalForSurvey(surveyId: number) {
		/**
		 * @Devnote there's an issue here where once a survey is filled out,
		 * the user logs out, another user logs in and the survey is
		 * reshown, the previous user's answers are still there...
		 */
		if (this.surveyId) {
			// Need to figure out a way to `queue` surveys in case this
			// gets called from multiple locations
			throw new Error('Survey modal is already in use');
		}
		this.surveyId = surveyId;
		this.showModal = true;
	}

	public dismissModal() {
		this.showModal = false;
		this.surveyId = null;

		this.toastService.queueToast({
			duration: 5000,
			meta: 'Just Now',
			title: 'Survey Complete',
			content: 'Survey submitted. Thank you for your feedback!'
		});
	}
}

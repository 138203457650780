import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

import { Observable, of, Subject, from, combineLatest } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import * as Sentry from '@sentry/angular';

import { environment } from 'src/environments/environment';

import { AppState } from 'src/app/store/app-state.model';
import * as UserActions from '../../../store/user/user.actions';

import { User } from 'src/app/store/user/user.model';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	/**
	 * Observable Event to watch for Logout
	 *
	 * @Devnote - anything bound to the user should cancel
	 * their subscription when this emits.
	 *
	 * @author LWK<lew@dankestudios.com>
	 */
	private _loggedOutEmitter: Subject<any> = new Subject();
	public get logoutEmitter() {
		return this._loggedOutEmitter;
	}

	constructor(
		private store: Store,
		private http: HttpClient,
		private fire: AngularFireAnalytics
	) {}

	tryLogin = (
		email: string,
		password: string
	): Observable<string | boolean> =>
		this.authenticateUser(email, password).pipe(
			tap((user: User) => {
				this.store.dispatch(UserActions.login(user));
				combineLatest(
					from(this.fire.setUserId(user.id)),
					from(this.fire.setUserProperties(user))
				)
					.pipe(
						catchError(error => {
							console.error(
								'Error setting user data in firebase.'
							);
							return of(false);
						})
					)
					.subscribe();
			}),
			tap((user: User) => this.setSentryUser(user)),
			map((user: User) => user.id),
			catchError(error => {
				// handle error
				console.log('Error authenticating user', error);
				return of(false);
			})
		);

	authenticateUser(email, password): Observable<User> {
		const call = this.http.post<User>(
			`${environment.api_base_url}/user/login`,
			{ email: email, password: password }
		);
		return call;
	}

	validateIsActivelyAuthenticated(): Observable<boolean> {
		return this.http
			.get<string>(`${environment.api_base_url}/user/isAuthenticated`)
			.pipe(map(response => response !== 'Unauthorized.'));
	}

	registerUserAndLogin = (
		firstName,
		lastName,
		email,
		password,
		referrer = null,
		ambassadorId = null
	): Observable<any> =>
		this.registerUser(
			firstName,
			lastName,
			email,
			password,
			referrer,
			ambassadorId
		).pipe(
			catchError(e => {
				console.error(e);
				window.alert(e);
				return of(false);
			}),
			switchMap(success =>
				success ? this.tryLogin(email, password) : of(false)
			),
			tap(id => console.log(id))
		);

	registerUser(
		firstName,
		lastName,
		email,
		password,
		referrer = null,
		ambassadorId = null
	): Observable<string> {
		const params = {
			first_name: firstName,
			last_name: lastName,
			email: email,
			password: password
		};
		if (null !== ambassadorId) {
			params['ambassador-id'] = ambassadorId;
		} else {
			params['referrer'] = referrer;
		}
		const call = this.http.put<string>(
			`${environment.api_base_url}/user/`,
			params
		);
		return call;
	}

	doLogOut() {
		return this.logUserOut().pipe(
			tap(res => {
				this._loggedOutEmitter.next();
				this.store.dispatch(UserActions.logout());
			})
		);
	}

	logUserOut() {
		return this.http.get<any>(`${environment.api_base_url}/user/logout`);
	}

	getUserFromToken(token: any): Observable<User> {
		return of(<User>{});
	}

	checkValidReferrer(userId: string): Observable<boolean> {
		if (environment.anyone_can_register) {
			return of(true);
		}
		if (!userId) {
			return of(false);
		}
		const call = this.http
			.get<boolean>(
				`${environment.api_base_url}/user/${userId}/validateReferral`
			)
			.pipe(
				catchError(e => {
					console.log(e);
					return of(false);
				})
			);
		return call;
	}

	requestPasswordReset(email: string): Observable<boolean> {
		return this.http
			.post<string>(`${environment.api_base_url}/user/forgot-password`, {
				email
			})
			.pipe(
				map(res => {
					return res === 'true';
				}),
				catchError(e => {
					return of(false);
				})
			);
	}

	updatePasswordWithResetToken(
		uuid: string,
		token: string,
		password: string
	): Observable<boolean> {
		return this.http
			.post<any>(`${environment.api_base_url}/user/reset-password`, {
				uuid,
				token,
				password
			})
			.pipe(
				map(res => {
					return res === 'true';
				}),
				catchError(e => {
					return of(false);
				})
			);
	}

	setSentryUser(user: User): void {
		// configure sentry user
		console.log('Set sentry user');
		Sentry.setUser({ id: user.id, email: user.email });
	}
}

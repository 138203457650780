import { Component, OnInit, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'app-slide',
	templateUrl: './slide.component.html',
	styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
	@HostBinding('class.glide__slide') glideSlideClassName = true;

	constructor() {}

	ngOnInit() {}
}

<sas-card>
	<sas-card-header>
		<strong class="card-title mb-0 d-block">Send an Invite <i class="fas fa-paper-plane text-muted"></i></strong>
		<small>Invite your friends!</small>
	</sas-card-header>
	<sas-card-body>

		<div *ngIf="inviteUrl!== null">

			<div class="alert alert-primary mb-3">
				<strong>Invite sent!</strong>
				<p class="alert-text">
					We just sent an invite to <strong [innerHTML]="form.controls.email.value"></strong>.
					You can also copy and paste the invite link from below into a text or email to send
					it to them directly.
				</p>
				<div class="d-flex flex-column flex-lg-row">
					<button class="btn btn-link p-0 text-left mb-3 mb-md-0" [copy-clipboard]="inviteUrl" ngbTooltip="Link copied!" triggers="click:blur">
						{{inviteUrl}}
					</button>
					<div class="flex-shrink-lg-0 ml-lg-2">
						<button class="btn btn-primary" [copy-clipboard]="inviteUrl" ngbTooltip="Link copied!" triggers="click:blur" >
							<i class="fas fa-link"></i>
						</button>
					</div>
				</div>
			</div>

			<button (click)="reset()" class="btn btn-primary">Send another invite</button>
		</div>

		<form *ngIf="inviteUrl===null" [formGroup]="form" (ngSubmit)="onSubmit()">
			<div class="form-group" [class.was-validated]="form.controls.email.dirty && form.controls.email.value !== ''">
				<label for="email">Email</label>
				<input type="email" name="email" formControlName="email" class="form-control" placeholder="Recipient's email address"/>
				<small class="form-text text-muted mt-3">
					Enter the email address of the friend, colleague or foe that you want to send an invite to.
				</small>
			</div>
			<div class="form-group">
				<button class="btn btn-primary" [disabled]="form.valid ? null : true">Send Invite</button>
			</div>
		</form>

	</sas-card-body>
</sas-card>

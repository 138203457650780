import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Survey } from 'src/app/interfaces/survey';
import { QUESTION_TYPES, SurveyService } from 'src/app/services/survey.service';

@Component({
	selector: 'app-survey',
	templateUrl: './survey.component.html',
	styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
	@Input() survey: Survey;

	@Input() surveyId: number;

	$survey: Observable<Survey> = this.surveyService.surveys.pipe(
		map(surveys =>
			this.survey
				? this.survey
				: surveys.find(s => s.id === this.surveyId)
		)
	);

	questionTypes = QUESTION_TYPES;

	constructor(public surveyService: SurveyService) {}

	ngOnInit() {}

	/**
	 * Handle Conditional Response Display
	 *
	 */
	shouldShowQuestion(question, survey): boolean {
		if (!question.is_conditional) {
			return true;
		}
		// Example is `4 contains 5` as in question with
		// index 4 contains the title of the question
		// with index 5. Not sure how to make this dynamic...
		switch (question.conditional_expression) {
			case '4 contains 5':
				return (
					survey.questions[4].response &&
					survey.questions[4].response.some(
						r => r.indexOf(question.question) > -1
					)
				);
			default:
				return true;
		}
	}
}

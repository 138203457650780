import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[appSpriteIcon]'
})
export class SpriteIconDirective {
	@Input('appSpriteIcon') appSasIcon: string;

	constructor(private el: ElementRef) {}

	ngOnInit() {
		this.el.nativeElement.classList.add('sprite-icon');
		this.el.nativeElement.classList.add(`sprite-icon--${this.appSasIcon}`);
		this.el.nativeElement.style.height = '100%';
		this.el.nativeElement.style.width = '100%';
		this.el.nativeElement.innerHTML = `<use xlink:href="/assets/svg/sprites/sprite.svg#sprite-${this.appSasIcon}"></use>`;
	}

	ngOnChanges() {
		this.el.nativeElement.innerHTML = `<use xlink:href="/assets/svg/sprites/sprite.svg#sprite-${this.appSasIcon}"></use>`;
	}
}

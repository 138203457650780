import ReportDataModel from '../interfaces/report-data';
import ReportDataTraitModel from '../interfaces/report-data-trait';
import TraitAggregateModel from '../interfaces/trait-aggregate';

/**
 * @Devnote should we include the trait information in each on of these, or fall back on the report service?
 *
 * @Todo in general, we should try to push the formatting logic into this service
 * @todo Add traitSegment retrieval functionality
 * @todo Add trait detail retrieval functionality
 */
export class ReportFlattener {
    private _traitAggregates: TraitAggregateModel[];
    public get traitAggregates() {
        return this._traitAggregates;
    }

    constructor(private data: ReportDataModel[]) {
        if (data.length > 0) {
            this.data = this.data.sort((a, b) => a.id > b.id ? -1 : 1);
            console.log('new FlattenedReport() =>', this.data);
            this.setupTraitAggregateModels();
            this.addResultsToTraitAggregateModels();
            this.calculateTraitResultAverages();
        }
        return this;
    }

    /**
     * Sort Report
     * @Devnote refactor the sort algorythm to be reusable?
     * @param key number|''average'
     * @return void
     */
    public sortTraitsBy(key: number) {
        if (key === -1) {
            this._traitAggregates.sort((a, b) => {
                return a.averages.score === b.averages.score ? (
                    a.averages.avgResponseTime === a.averages.avgResponseTime ?
                    0 : (a.averages.avgResponseTime < b.averages.avgResponseTime ? 1 : -1)
                ) : (
                    a.averages.score < b.averages.score ? 1 : -1
                );
            });
        } else {
            this._traitAggregates.sort((a, b) => {
                return a.results[key].score === b.results[key].score ? (
                    a.results[key].avgResponseTime === a.results[key].avgResponseTime ?
                    0 : (a.results[key].avgResponseTime < b.results[key].avgResponseTime ? 1 : -1)
                ) : (
                    a.results[key].score < b.results[key].score ? 1 : -1
                );
            });
        }
        console.log(`sortTraitsBy() => (key: ${key})`, this._traitAggregates);
        return this;
    }

    /**
     * Create Trait Aggregate Models to hold trait data
     * @param null
     * @return void
     */
    private setupTraitAggregateModels(): void {
        this._traitAggregates = this.data[0].data
            .map((datum: ReportDataTraitModel) => {
                return <TraitAggregateModel>{
                    traitId: datum.id,
                    averages: { score: 0, dominance: 0, avgResponseTime: 0 },
                    results: []
                };
            })
            .sort((a, b) => (a.traitId > b.traitId ? 1 : -1));
        // console.log('setupTraitAggregateModels() =>', this._traitAggregates);
    }

    /**
     * Loop through assessment results and add trait scores to aggregates
     * @param null
     * @return void
     */
    private addResultsToTraitAggregateModels(): void {
        this.data.forEach((datum: ReportDataModel) => {
            datum.data.forEach((traitResult: ReportDataTraitModel) => {
                const trait = this._traitAggregates[traitResult.id - 1];
                trait.results.push(
                    Object.assign({}, traitResult, {assessmentId: datum.id, created_at: datum.updated_at})
                );
                ['score', 'dominance', 'avgResponseTime'].forEach((key) => trait.averages[key] += traitResult[key]);
            });
        });
        // console.log('addResultsToTraitAggregateModels() =>', this._traitAggregates);
    }

    /**
     * Calculate averages across TraitAggregates
     * @param null
     * @return void
     */
    private calculateTraitResultAverages(): void {
        this._traitAggregates.forEach(trait => {
            const divisor = trait.results.length;
            ['score', 'dominance', 'avgResponseTime'].forEach(key => {
                trait.averages[key] = trait.averages[key] / divisor;
            });
        });
        // console.log('calculateTraitResultAverage() =>', this._traitAggregates);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodoComponent } from './components/todo/todo.component';
import { SurveyComponent } from './components/survey/survey.component';
import { SurveyModalComponent } from './components/survey-modal/survey-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SurveyQuestionComponent } from './components/survey-question/survey-question.component';
import { HelpFormComponent } from './components/help-form/help-form.component';
import { FeatureInterestButtonComponent } from './components/feature-interest-button/feature-interest-button.component';
import { FeatureInterestPromptComponent } from './components/feature-interest-prompt/feature-interest-prompt.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackFabComponent } from './components/feedback-fab/feedback-fab.component';
import { PostAssessmentSurveyPromptComponent } from './components/prompts/post-assessment-survey-prompt/post-assessment-survey-prompt.component';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [
		TodoComponent,
		SurveyModalComponent,
		SurveyComponent,
		SurveyQuestionComponent,
		HelpFormComponent,
		FeatureInterestButtonComponent,
		FeatureInterestPromptComponent,
		FeedbackFabComponent,
		PostAssessmentSurveyPromptComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		RouterModule
	],
	exports: [
		TodoComponent,
		SurveyModalComponent,
		SurveyComponent,
		SurveyQuestionComponent,
		HelpFormComponent,
		FeatureInterestButtonComponent,
		FeatureInterestPromptComponent,
		FeedbackFabComponent,
		PostAssessmentSurveyPromptComponent
	]
})
export class TestingModule {}

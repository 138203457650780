import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { User } from './user/user.model';
import { userReducer } from '../store/user/user.reducer';

import { UserActivity } from './user-activity/user-activity.model';
import { userActivityReducer } from '../store/user-activity/user-activity.reducer';

/**
 * App State representation
 *
 */
export interface AppState {
	user: User;
	userActivity: UserActivity;
	version?: string;
}

/**
 * Reducers configuration
 *
 */
export function localStorageSyncReducer(
	reducer: ActionReducer<any>
): ActionReducer<any> {
	return localStorageSync({
		keys: ['user', 'userActivity', 'version'],
		rehydrate: true
	})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
	localStorageSyncReducer
];

export const reducers: ActionReducerMap<AppState> = {
	user: userReducer,
	userActivity: userActivityReducer
};

/**
 * State Selectors
 * Retrieve slices from the global state object
 * @see https://ngrx.io/guide/store/selectors
 */
export const selectUser = (state: AppState) => state.user;
export const selectUserActivity = (state: AppState) => state.userActivity;
export const selectVersion = (state: AppState) => state.version;

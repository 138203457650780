import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UserOnboardingModalService {
	private _showModal: boolean = false;
	get showModal() {
		return this._showModal;
	}
	set showModal(show: boolean) {
		this._showModal = show;
	}
	constructor() {}
}

<div class="modal modal-trait fade d-block show" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<strong class="modal-title">{{(currentSurvey|async)?.title}}</strong>
			</div>
            <div class="modal-body">
				<app-survey
					*ngIf="surveyModalService.surveyId"
					[surveyId]="surveyModalService.surveyId"
				></app-survey>
				<button class="btn btn-primary my-3" (click)="close($event, true)">Submit</button>
			</div>
		</div>
    </div>
</div>
<div class="modal-backdrop fade show"></div>

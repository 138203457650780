<!-- @Trait Icon -->
<span class="trait-icon-wrapper" [class.bordered]="border">
  <span class="trait-icon">
      <span class="badge" *ngIf="badge" [innerText]="badge"></span>
      <svg [appSpriteIcon]="traitSafeName"></svg>
  </span>
</span>
<!-- @Trait Name -->
<span *ngIf="showName" class="trait-name">
    <span [innerText]="trait.name_general"></span>
</span>

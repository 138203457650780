import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';

export interface ToastConfig {
  title: string;
  meta?: string;
  content?: string;
  duration?: number;
}

@Component({
  selector: 'sas-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  @Input()
  id;

  @Input()
  title;

  @Input()
  meta;

  @Input()
  content;

  @Input()
  duration: number = 5000;

  @Output()
  dismiss: EventEmitter<any> = new EventEmitter();

  /**
   * This is the default bootstrap classmap for the
   * toast lifecycle.
   * 
   * ```ts
   * const ClassName = {
   *  FADE    : 'fade',
   *  HIDE    : 'hide',
   *  SHOW    : 'show',
   *  SHOWING : 'showing'
   * }
   * ```
   */

  @HostBinding('class.toast')
  className = true;

  @HostBinding('class.fade')
  classFade = false;

  @HostBinding('class.showing')
  classShowing = false;

  @HostBinding('class.show')
  classShow = true;

  @HostBinding('class.hide')
  classHide = false;

  @HostBinding('class.out')
  classOut = false;

  @HostBinding('attr.role')
  role = 'alert';

  @HostBinding('attr.aria-live')
  ariaLive = 'assertive';

  @HostBinding('attr.aria-atomic')
  ariaAtomic = true;

  constructor() { }

  /**
   * Automatically dismiss the toast component
   * after the given duration.
   * 
   * @author LWK
   */
  ngOnInit() {

    if (this.duration > 0) {
      setTimeout(
        () => this._dismiss(),
        this.duration
      );
    }
  }

  /**
   * Tell the toast service to dismiss this toast component
   * 
   * @author LWK <lew@dankestudios.com>
   */
  _dismiss() {
    this.classOut = true;
    setTimeout(
      () => this.dismiss.emit( this.id ),
      1000
    )
  }

}

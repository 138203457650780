import { Component, OnInit, HostBinding, OnChanges } from "@angular/core";
import { TraitService } from "src/app/services/trait.service";
import { DemographicsService } from "src/app/services/demographics.service";
import { Observable } from "rxjs";
import { Trait } from "src/app/interfaces/trait";
import { ActivatedRoute, UrlSegment, Router } from "@angular/router";
import {
    map,
    switchMap,
    take,
    tap,
    share,
    withLatestFrom,
    takeLast
} from "rxjs/operators";

@Component({
    selector: "app-trait-detail",
    templateUrl: "./trait-detail.component.html",
    styleUrls: ["./trait-detail.component.scss"]
})
export class TraitDetailComponent implements OnInit {
    trait$: Observable<Trait>;

    demographics$: Observable<any>;

    routes$: Observable<any[]>;

    activeSubNavItemIndex = 0;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private traitService: TraitService,
        private demographicsService: DemographicsService
    ) {}

    /**
     * Grab the TraitId from the route params and
     * map use it to get our trait.
     *
     * @author LWK
     */
    ngOnInit() {
        this.trait$ = this.route.params.pipe(
            map(d => d.traitId),
            switchMap(traitId => this.traitService.getTrait(traitId)),
        );

        this.demographics$ = this.trait$.pipe(
            switchMap(trait =>
                this.demographicsService.getDemographics(trait.id)
            )
        );

        this.routes$ = this.trait$.pipe(
            tap(() => {
                // Update active nav items based on route
                const segments = this.router.url.split("/");
                if (segments.length > 3) {
                    this.activeSubNavItemIndex = segments.includes("details") ? 1 : 2;
                } else {
                    this.activeSubNavItemIndex = 0;
                }
            }),
            map(trait => {
                return [
                    {
                        text: "Feed",
                        link: ["/", "traits", trait.id]
                    },
                    {
                        text: "Trait Info",
                        link: ["/", "traits", trait.id, "details"]
                    },
                    {
                        text: "Friends",
                        link: ["/", "traits", trait.id, "friends"]
                    }
                ];
            })
        );
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast/toast.component';
import { ToastService } from './toast/toast.service';
import { ToastOutletComponent } from './toast/toast-outlet/toast-outlet.component';

@NgModule({
	imports: [CommonModule],
	declarations: [ToastComponent, ToastOutletComponent],
	exports: [ToastOutletComponent]
	//   entryComponents: [ToastComponent],
})
export class GlobalModule {}

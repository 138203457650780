import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import * as UserActions from '../store/user/user.actions';

@Injectable({
	providedIn: 'root'
})
export class UserPreferencesService {
	private _userPreferences: Observable<{
		[key: string]: any;
	}> = this.userService.user.pipe(
		takeUntil(this.userService.loggedOutNotifier),
		filter(user => user !== null),
		map(user => user.preferences)
	);
	get userPreferences() {
		return this._userPreferences;
	}
	constructor(
		private http: HttpClient,
		private store: Store,
		private userService: UserService
	) {}

	updateUserPreference(key: string, value: any): Observable<void> {
		return this.http
			.patch<any>(`${environment.api_base_url}/me/preferences`, {
				[key]: value
			})
			.pipe(
				// @debug
				tap(success =>
					this.store.dispatch(
						UserActions.refresh({
							preferences: {
								[key]: value
							}
						})
					)
				)
			);
	}
}

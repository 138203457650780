import {
	Component,
	OnInit,
	Input,
	HostBinding,
	ElementRef,
} from '@angular/core';

@Component({
	selector: 'sas-card-body',
	template: '<ng-content></ng-content>',
	styleUrls: ['./card-body.component.scss'],
})
export class CardBodyComponent implements OnInit {
	@Input() classes: string[] = [];
	@Input() attributes: string[];

	@Input('class')
	@HostBinding('class')
	className: string = '';

	@HostBinding('class.gray')
	@Input()
	gray = false;

	@HostBinding('class.card-body') classCardBody = true;
	@HostBinding('class.sas-card-body') classSasCardBody = true;

	@Input('id')
	@HostBinding('id')
	id = null;

	constructor(private el: ElementRef) {}

	ngOnInit() {
		this.className += ` ${this.classes.join(' ')}`;
	}
}

import { createReducer, on } from '@ngrx/store';
import * as UserActivityActions from './user-activity.actions';
import * as UserActions from '../user/user.actions';
import { UserActivity, UserComparisonActivity } from './user-activity.model';
import { loadActivity, logComparison, clear } from './user-activity.actions';

export type Action = UserActivityActions.All | UserActions.All;

const defaultState: UserActivity = { comparisons: [] };

// Reducer
export function UserActivityReducer(state: UserActivity = defaultState, action: Action) {
  // console.log('UserReducer() => (state, action)', state, action);

  switch (action.type) {
    case UserActivityActions.LOAD_COMPARISONS: {
      return {
        ...state,
        comparisons: action.payload,
      };
    }

    case UserActivityActions.LOG_COMPARISON: {
      return Object.assign(
        {},
        { ...state },
        {
          // We sure we aren't mutating?
          comparisons: [
            action.payload,
            ...state.comparisons.filter(
              comparison => comparison.user.id !== action.payload.user.id,
            ),
          ],
        },
      );
    }

    case UserActions.LOGOUT:
    case UserActivityActions.CLEAR: {
      return defaultState;
    }

    default: {
      return state;
    }
  }
}

/**
 * NgRx@12.4.0 Updates
 *
 */
const initialState: UserActivity = { comparisons: [] };

const handleLoadComparisons = (
  state: UserActivity,
  payload: { comparisons: UserComparisonActivity[] },
) => {
  return {
    ...state,
    comparisons: payload.comparisons,
  };
};
const handleLogComparison = (
  state: UserActivity,
  payload: { comparison: UserComparisonActivity },
) => {
  return {
    ...state,
    comparisons: [
      ...state.comparisons.filter(comparison => comparison.user.id !== payload.comparison.user.id),
      payload.comparison,
    ],
  };
};
const handleClear = (state: UserActivity) => {
  return initialState;
};

// tslint:disable-next-line:variable-name
const _userActivityReducer = createReducer(
  initialState,
  on(loadActivity, handleLoadComparisons),
  on(logComparison, handleLogComparison),
  on(clear, handleClear),
);

export function userActivityReducer(state, action) {
  return _userActivityReducer(state, action);
}

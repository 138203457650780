<ng-container *ngIf="trait$ | async as trait">

    <div class="alert alert-primary container-fluid">
        <div class="d-flex my-2 small">
            <a class="btn btn-sm btn-outline-primary mr-3" [routerLink]="['/', 'traits']">
                <span class="fa fa-arrow-left"></span> All Traits
            </a>
            <a *ngIf="trait.id > 1" class="btn btn-sm btn-primary" [routerLink]="['/', 'traits', +trait.id - 1]">
                <span class="fa fa-chevron-left"></span> Previous Trait
            </a>
            <a *ngIf="trait.id < 25" class="btn btn-sm btn-primary ml-auto"
                [routerLink]="['/', 'traits', +trait.id + 1]">
                Next Trait <span class="fa fa-chevron-right"></span>
            </a>
        </div>
    </div>

    <!-- <app-trait-detail-header [trait]="trait"></app-trait-detail-header> -->

    <div class="container my-5">
        <div class="row justify-content-center">
            <!-- @Nav -->
            <div class="col-lg-2">
                <nav *ngIf="routes$|async as routes">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" *ngFor="let route of routes; let i = index">
                            <a [routerLink]="route.link" (click)="activeSubNavItemIndex=i"
                                [class.active]="i === activeSubNavItemIndex" class="nav-link">{{route.text}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- @Content -->
            <div class="col-lg-7">
                <router-outlet></router-outlet>
            </div>
            <!-- @Sidebar -->
            <div class="col-lg-3">
                <sas-card>
                    <sas-card-header>
                        <strong class="card-title">Quote of the day</strong>
                    </sas-card-header>
                    <sas-card-body>
                        <blockquote class="blockquote">
                            <p>{{trait.quote.content}}</p>
                            <footer class="blockquote-footer">{{trait.quote.author}}</footer>
                        </blockquote>
                    </sas-card-body>
                </sas-card>
            </div>
        </div>
    </div>

</ng-container>
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';

import { AppComponent } from './app.component';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Debug
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ComponentsModule } from './components/components.module';

// Store
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';

// Http Interceptors
import { httpInterceptorProviders } from './interceptors/index';

// Twitter Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Directives
import { DirectivesModule } from './directives/directives.module';

// Global
import { FeedItemComponent } from './ui/feed/feed-item/feed-item.component';
import { SasUiModule } from './ui/sas-ui.module';
import { GlobalModule } from './modules/global/global.module';
import { TraitsModule } from './modules/traits/traits.module';
import { TestingModule } from './modules/testing/testing.module';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import {
	AngularFireAnalyticsModule,
	ScreenTrackingService,
	UserTrackingService,
	CONFIG,
	APP_NAME,
	APP_VERSION,
	DEBUG_MODE,
	COLLECTION_ENABLED,
} from '@angular/fire/compat/analytics';

// Debugging
import * as Sentry from '@sentry/angular';

import { environment } from '../environments/environment';

@NgModule({
	declarations: [AppComponent, FeedItemComponent],
	imports: [
		AppRoutingModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAnalyticsModule,
		ComponentsModule,
		DirectivesModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		NgbModule,
		SasUiModule,
		GlobalModule,
		// ShareModule.withConfig({
		// 	debug: true
		// }),
		StoreModule.forRoot(reducers, { metaReducers }),
		StoreDevtoolsModule.instrument({
			maxAge: 10,
		}),
		TraitsModule,
		TestingModule,
	],
	providers: [
		httpInterceptorProviders,
		ScreenTrackingService,
		UserTrackingService,
		{
			provide: COLLECTION_ENABLED,
			useValue: environment.production,
		},
		{
			provide: APP_VERSION,
			useValue: environment.version,
		},
		{
			provide: CONFIG,
			useValue: {
				send_page_view: environment.production,
			},
		},
		{
			provide: DEBUG_MODE,
			useValue: !environment.production,
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
			useValue: undefined,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(trace: Sentry.TraceService) {}
}

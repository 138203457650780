<div class="trait-abstract-content" [class.hide-icon]="!icon" *ngIf="trait">
    <div class="trait-detail">
        <p class="trait-name mb-0"><strong>{{ trait.name_general | titlecase }}</strong></p>
        <p class="strategy">{{ content }}</p>
        <div *ngIf="link" class="text-center">
            <a [routerLink]="['/', 'traits', trait.id, 'details']" class="btn btn-light btn-block">
                <i class="fas fa-arrow-right mr-2"></i> Full trait detail
            </a>
        </div>
    </div>
    <div *ngIf="icon" class="trait-icon-wrapper bg-primary">
        <span class="trait-icon">
            <svg [appSasIcon]="traitSafeName"></svg>
        </span>
    </div>
</div>

import { APP_VERSION } from './version';
export const environment = {
	production: true,
	version: APP_VERSION,
	api_base_url: '/api/v2',
	sas_video_url_youtube: 'https://www.youtube.com/embed/KSgKbFW-718',
	client_token: null,
	assessment_renewal_months: 6,
	geonames_base_url: 'http://api.geonames.org',
	anyone_can_register: false,
	firebase: {
		apiKey: 'AIzaSyD_A42AR7W6LibHsRoKqWMqwmMYabv6wlY',
		authDomain: 'shareastrength.firebaseapp.com',
		databaseURL: 'https://shareastrength.firebaseio.com',
		projectId: 'shareastrength',
		storageBucket: 'shareastrength.appspot.com',
		messagingSenderId: '839227929035',
		appId: '1:839227929035:web:6dfd2c93b0a069b8a7464e',
		measurementId: 'G-5VB8T0DCNR'
	},
	enabled_features: {
		trait_feedback: false,
		trait_insights: false
	}
};

import { Component, HostBinding, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UserService as ReportUserService } from 'src/app/modules/user/services/user.service';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UserActivityService } from 'src/app/services/user-activity.service';
import { LayoutService } from './layout.service';
import { InviteFriendModalService } from '../invite-friend-modal/invite-friend-modal.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss'],
})
/**
 * Layout Component is used to handle general app
 * layout, exposing multiple named router outlets,
 * specifically sidebarMain and sidebarSecondary
 * which are set to defaults at this point.
 *
 * @author LWK<lew@dankestudios.com>
 */
export class LayoutComponent implements OnInit {
	/**
	 * This is a HACK solution I added because I cannot
	 * figure out named router outlets. We'll need to revisit
	 * before scaling.
	 * @author LWK
	 */
	get fullWidth() {
		return this.layoutService.fullWidth;
	}

	@HostBinding('class.full-width')
	get fullWidthClass() {
		return this.layoutService.fullWidth;
	}

	/**
	 * @TODO move everything below to its own consideration
	 */

	/**
	 * NEW - Use Store to Retrieve Auth User Ordered Traits
	 * @author LWK
	 */
	orderedTraits$: Observable<any> = this.userService.user.pipe(
		switchMap((user) =>
			this.reportUserService.getOrderedTraitsForUser(user.id, true)
		),
		map((t) => t.slice(0, 5))
	);

	userComparisonActivity$ = this.userActivityService
		.getUserComparisonActivity()
		.pipe(map((activity) => activity.slice(0, 10)));

	onboardingSuggestions$ = this.userService.user.pipe(
		map((user) => [
			{
				slug: 'top-5',
				title: 'Learn about your top 5 traits.',
				content: `Learn more about yourself by reading about your innate strengths and blindspots.`,
				link: ['/', 'me', 'report'],
			},
			{
				slug: 'feed',
				title: 'Explore the Home Feed.',
				content: `Get a birdseye view of everything occuring on SAS. Learn insights about yourself and others.`,
				link: ['/', 'me'],
			},
			{
				slug: 'invite',
				title: 'Invite your Friends',
				content: `Invite friends and people in your network to take the assessment and compare.`,
				link: null,
				handler: () => this.inviteFriendModalService.showModal(),
			},
			{
				slug: 'compare',
				title: 'Compare with friends.',
				content: `Guess their top 5 strengths and get valuable insight into your relationships.`,
				link: ['/', 'me', 'community'],
			},
			{
				slug: 'browse',
				title: 'Browse other Profiles',
				content: `Gain insights and build stronger relationships by comparing and contrasting your traits.`,
				link: ['/', 'me', 'community', 'suggestions'],
			},
		])
	);

	/**
	 * Check if we're currently viewing the logged in user's
	 * report page.
	 * @var boolean
	 * @author LWK
	 */
	$viewingReport: Observable<boolean> = this.router.events.pipe(
		startWith(this.router.url === '/me/report'),
		map(() => {
			return this.router.url === '/me/report';
		})
	);

	constructor(
		public layoutService: LayoutService,
		public userService: UserService,
		public reportUserService: ReportUserService,
		private userActivityService: UserActivityService,
		private inviteFriendModalService: InviteFriendModalService,
		private router: Router
	) {}

	ngOnInit() {}

	handleClick(item: { slug; title; content; link; handler? }) {
		if (item.handler) {
			item.handler();
		} else {
			this.router.navigate(item.link);
		}
	}
}

import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/modules/authentication/services/authentication.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/store/user/user.model';

import { selectUser } from '../../store';

import { SeoGuardService } from 'src/app/middleware/seo-guard.service';

@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
	@HostBinding('class') navbarClass =
		'navbar navbar-default navbar-expand sticky-top navbar-three-columns';

	@HostBinding('class.navbar-light')
	@Input()
	navbarColorLight = false;

	@HostBinding('class.navbar-dark') navbarColorDark = false;
	@HostBinding('id') id = 'app-navigation';

	@HostBinding('attr.aria-label') ariaLabel = 'Main Navigation';
	@HostBinding('attr.role') navRole = 'navigation';

	showMobileMenu = false;

	showSlideout = false;
	slideoutClassIn = false;
	slideoutClassOut = false;

	user$: Observable<User>;

	routes$: { text: string; link: string | string[] }[] = [];

	get currentPageTitle() {
		return this.seoGuardService.pageTitle;
	}

	constructor(
		private store: Store,
		private router: Router,
		private authService: AuthenticationService,
		private seoGuardService: SeoGuardService
	) {}

	ngOnInit() {
		this.user$ = this.store
			.select(selectUser)
			.pipe(tap((user) => this.setupRoutes(user)));
		this.navbarColorDark = !this.navbarColorLight;
	}

	setupRoutes(user: User) {
		if (user) {
			this.routes$ = [
				{
					text: 'Dashboard',
					link: `/me`,
				},
				{
					text: 'Profile',
					link: `/me/profile`,
				},
				{
					text: 'Compare',
					link: `/me/friends`, // @Todo make compare route
				},
				{
					text: 'My Report',
					link: `/me/report`,
				},
				{
					text: 'Account Settings',
					link: `/me/settings`,
				},
				{
					text: 'Help/Feedback',
					link: '#help',
				},
				{
					text: 'About ShareaStrength',
					link: '/about',
				},
			];
		}
	}

	isActiveRoute(routeItem: any): boolean {
		return this.router.url === routeItem.link;
	}

	routeClass(routeItem: any): string {
		return `nav-link ${this.isActiveRoute(routeItem) ? 'active' : ''}`;
	}

	toggleNavbarColor(): void {
		this.navbarColorDark = !(this.navbarColorLight =
			!this.navbarColorLight);
	}

	toggleSlideout(show: boolean) {
		if (show) {
			this.showSlideout = true;
			this.slideoutClassIn = true;
			this.slideoutClassOut = false;
		} else {
			this.slideoutClassIn = false;
			this.slideoutClassOut = true;
			const timeout = setTimeout(() => {
				this.slideoutClassOut = false;
				this.showSlideout = false;
			}, 300);
		}
	}

	username(user: User) {
		if (user) {
			return user.profile.first_name && user.profile.last_name
				? `${user.profile.first_name} ${user.profile.last_name}`
				: user.email;
		} else {
			return '';
		}
	}

	logout(): void {
		this.authService
			.doLogOut()
			.toPromise()
			.then(() => {
				/**
				 * @todo: move logout functionality to /auth/logout with route guard
				 */
				this.router.navigate(['auth', 'login']);
			});
	}
}

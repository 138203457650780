import {
	Component,
	ElementRef,
	HostBinding,
	OnInit,
	ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { UserService } from './services/user.service';
import { UserOnboardingModalService } from './components/user-onboarding-modal/user-onboarding-modal.service';
import { SurveyModalService } from './modules/testing/components/survey-modal/survey-modal.service';
import { HelpFormService } from './modules/testing/components/help-form/help-form.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	@HostBinding('attr.app-version')
	appVersion: string = environment.version;

	@HostBinding('class') hostClass: string;
	@HostBinding('attr.data-navigator') navigatorData: string;

	@ViewChild('navigation') navigationEl: ElementRef;

	@ViewChild('cta') ctaEl: ElementRef;

	constructor(
		private router: Router,
		public userService: UserService,
		public userOnboardingModalService: UserOnboardingModalService,
		public surveyModalService: SurveyModalService,
		public helpFormService: HelpFormService
	) {}

	ngOnInit(): void {
		this.navigatorData = window.navigator.userAgent;
		const navigatorClass = `${window.navigator.platform.toLowerCase()} ${window.navigator.product.toLowerCase()}`;
		this.router.events
			.pipe(filter((e) => e instanceof NavigationEnd))
			.subscribe(
				(event: NavigationEnd) =>
					(this.hostClass = `${navigatorClass} route-${event.urlAfterRedirects
						.split('/')
						.join('-')}`)
			);
	}
}

<div class="modal modal-trait fade d-block show" tabindex="-1" role="dialog" (click)="handleDialogClick($event)">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
			<div class="modal-header align-items-center">
				<div>
					<strong class="modal-title d-block">All Traits</strong>
					<!-- <small class="text-muted">Trait details</small> -->
				</div>
				<div class="slider-nav">
					<button class="btn btn-sm btn-outline-primary" (click)="close()">
						Dismiss
					</button>
				</div>
			</div>
            <div class="modal-body">
				<ul class="trait-list">
					<li class="trait-list-item" *ngFor="let trait of traits">
						<button (click)="showTraitModal(trait.id)" class="btn btn-link trait-list-item-button">{{trait.name_general}}</button>
					</li>
				</ul>
            </div>
			<!-- <div class="modal-footer"></div> -->
        </div>
    </div>
</div>
<div class="modal-backdrop fade show" (click)="close()"></div>

import { Component, HostBinding, OnInit } from '@angular/core';
import { HelpFormService } from './help-form.service';

@Component({
	selector: 'app-help-form',
	templateUrl: './help-form.component.html',
	styleUrls: ['./help-form.component.scss']
})
export class HelpFormComponent {
	categories = ['Comment', 'Question', 'Critique', 'Error/Bug Report'];

	category = null;

	message = '';

	constructor(public helpFormService: HelpFormService) {}

	clear() {
		this.message = null;
		this.category = null;
	}

	async dismiss() {
		await this.helpFormService.submitMessage(this.category, this.message);
		this.clear();
	}
}

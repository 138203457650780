import { Component, OnInit, Input } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';

@Component({
  selector: 'app-trait-information-card',
  templateUrl: './trait-information-card.component.html',
  styleUrls: ['./trait-information-card.component.scss']
})
export class TraitInformationCardComponent implements OnInit {

  @Input() trait: Trait;

  contentSections = ['sweetspot', 'blindspot', 'strategy'];

  constructor() { }

  ngOnInit() {
  }

}

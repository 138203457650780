import { Component, OnInit, Input } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';

@Component({
	selector: 'app-trait-abstract-card',
	templateUrl: './trait-abstract-card.component.html',
	styleUrls: ['./trait-abstract-card.component.scss']
})
export class TraitAbstractCardComponent {
	@Input() trait: Trait;

	@Input() link = true;

	@Input() icon = true;

	// Indicates whether to use third person or second person content...
	@Input() content_3p = true;

	get content() {
		return this.content_3p && this.trait.sweetspot.content_3p
			? this.trait.sweetspot.content_3p
			: this.trait.sweetspot.content;
	}

	get traitSafeName(): string {
		return this.trait.name_general
			.replace(/[,.\&]/g, '')
			.replace(/[\ ]+/g, '-')
			.toLowerCase();
	}

	constructor() {}
}

<div class="toast-header">
  <strong class="toast-title mr-auto">{{title}}</strong>
  <small *ngIf="meta">{{meta}}</small>
  <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="_dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="toast-body" *ngIf="content">
  {{content}}
</div>
<!-- TODO: add toast actions block -->
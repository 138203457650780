import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Trait } from 'src/app/interfaces/trait';
import { map } from 'rxjs/operators';
import { Data, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-trait-details',
	templateUrl: './trait-details.component.html',
	styleUrls: ['./trait-details.component.scss'],
})
export class TraitDetailsComponent implements OnInit {
	trait$: Observable<Trait> = this.route.data.pipe(map((d: Data) => d.trait));

	constructor(private route: ActivatedRoute) {}

	ngOnInit() {}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from 'src/app/modules/global/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class HelpFormService {
	private _show = false;
	public get show() {
		return this._show;
	}
	public set show(show) {
		this._show = show;
	}

	constructor(private http: HttpClient, private toastService: ToastService) {}

	showModal() {
		this._show = true;
	}

	hideModal() {
		this._show = false;
	}

	/**
	 * Submit feedback to api.
	 *
	 * @stubbed
	 * @param category
	 * @param message
	 */
	public async submitMessage(category: string, message: string) {
		console.log('Submit', category, message);
		// @todo setup endpoint
		const success = await this.http
			.post<any>(`${environment.api_base_url}/help/general-feedback`, {
				category,
				message
			})
			.pipe(
				catchError(e => {
					console.error('Submission error', e);
					return EMPTY;
				})
			)
			.toPromise();
		console.log('Message submitted', success);

		this.toastService.queueToast({
			meta: 'Just now',
			title: 'Message Sent!',
			content: 'Your message has been sent. Thanks for your feedback.'
		});

		this.hideModal();
	}
}

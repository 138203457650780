import { Component, Input, OnInit } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';
import { TraitInsight } from 'src/app/interfaces/trait-insight';

interface ExtendedTraitInsight extends TraitInsight {
	parsedNumberText: string;
	parsedNumber: string;
	parsedTitle: string;
}

@Component({
	selector: 'app-insight-group',
	templateUrl: './insight-group.component.html',
	styleUrls: ['./insight-group.component.scss']
})
export class InsightGroupComponent implements OnInit {
	@Input() data: {
		insight: ExtendedTraitInsight;
		trait: Trait;
	}[];

	trait: Trait;

	ngOnInit() {
		this.trait = this.data[0].trait;
		this.data.map(d => {
			const matches = d.insight.insightText.match(/[\d.\%]{2,}/);
			d.insight.parsedNumberText = matches ? matches[0] : null;
			if (d.insight.parsedNumberText !== null) {
				d.insight.parsedNumber = d.insight.parsedNumberText.replace(
					'%',
					''
				);
			}

			d.insight.parsedTitle = this.parseInsightTitle(
				d.insight.insightText
			);
			return d;
		});
	}

	parseInsightTitle(text: string): string {
		const value = text.toLowerCase();
		if (value.indexOf('average age') > -1) {
			return 'Average Age';
		} else if (value.indexOf('top trait') > -1) {
			return 'Top Trait';
		} else if (value.indexOf('top five') > -1) {
			return 'Top Five';
		}
	}
}

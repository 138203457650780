export interface UserRole {
	id: number;
	name:
		| 'superadmin'
		| 'admin'
		| 'subscriber'
		| 'basic'
		| 'testerseed'
		| 'testeralpha';
	description: string;
	created_at: string;
	updated_at: string;
	pivot: {
		user_id: string;
		role_id: 1;
		created_at: string;
		updated_at: string;
	};
}
export enum USER_ROLE_ID_MAP {
	SUPERADMIN = 1,
	ADMIN = 2,
	SUBSCRIBER = 3,
	BASIC = 4,
	TESTER_SEED = 5,
	TESTER_ALPHA = 6
}

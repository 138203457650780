import {
	Component,
	OnInit,
	ViewChild,
	ViewContainerRef,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
} from '@angular/core';
import { ToastService } from '../toast.service';
import { ToastConfig, ToastComponent } from '../toast/toast.component';
import { take, filter } from 'rxjs/operators';
import { uniqueId } from 'lib/unique-identifier/unique-id';

@Component({
	selector: 'sas-toast-outlet',
	styleUrls: ['./toast-outlet.component.scss'],
	template: `<div #toastOutlet></div>`,
})
export class ToastOutletComponent implements OnInit {
	private toasts = [];

	@ViewChild('toastOutlet', { read: ViewContainerRef })
	toastOutlet: ViewContainerRef;

	constructor(
		private toastService: ToastService,
		private componentFactoryResolver: ComponentFactoryResolver
	) {}

	/**
	 * Let's watch the toast queue and add any new toast to our
	 * view container.
	 *
	 * @author LWK <lew@dankestudios.com>
	 */
	ngOnInit() {
		this.toastService.toastQueue
			.pipe(filter((config) => config !== null))
			.subscribe((config) => this.loadToastComponent(config));
	}

	/**
	 * This dynamically creates the component and injects it into
	 * the viewcontainer. Then we pass it off to resolve the
	 * component properties.
	 *
	 * @author LWK
	 *
	 * @param config config to create the toast element with
	 */
	private loadToastComponent(config: ToastConfig) {
		const componentFactory: ComponentFactory<ToastComponent> =
			this.componentFactoryResolver.resolveComponentFactory(
				ToastComponent
			);
		const componentRef: ComponentRef<ToastComponent> =
			this.toastOutlet.createComponent(componentFactory);
		this.resolveComponentProps(
			<ToastComponent>componentRef.instance,
			config
		);
	}

	/**
	 * Let's add all props to the toast component before
	 * render. Lastly, we'll add the components ID to our
	 * local array to keep track of when we want to remove
	 * it from the DOM.
	 *
	 * @param instance componentRef
	 * @param config toastConfig
	 */
	private resolveComponentProps(
		instance: ToastComponent,
		config: ToastConfig
	) {
		Object.keys(config).forEach((prop) => (instance[prop] = config[prop]));
		instance.id = uniqueId();
		instance.dismiss
			.pipe(take(1))
			.subscribe((id: any) => this.dismissToastComponent(id));
		this.toasts.push(instance.id);
	}

	/**
	 * Remove the toast from the parent container
	 *
	 * Note: We need to look into garbage collection on this
	 * and make sure we're not leaking anything.
	 *
	 * @author LWK <lew@dankestudios.com>
	 *
	 * @param id identifier for the toast component
	 */
	private dismissToastComponent(id: any) {
		const index = this.toasts.indexOf(id);
		this.toastOutlet.remove(index);
		this.toasts.splice(index, 1);
	}
}

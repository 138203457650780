import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	HostBinding,
} from '@angular/core';

@Component({
	selector: 'app-navigation-static',
	template: `
		<div *ngFor="let item of navItems" class="nav-item">
			<a
				class="nav-link"
				routerLinkActive="active"
				[routerLinkActiveOptions]="{ exact: item.exactMatch }"
				[routerLink]="item.routerLink"
				[innerHTML]="item.text"
			></a>
		</div>
	`,
	styleUrls: ['./navigation-static.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationStaticComponent implements OnInit {
	navItems = [
		{
			text: 'Home',
			routerLink: '/me',
			exactMatch: true,
		},
		// {
		// 	text: 'Compare',
		// 	routerLink: '/me/community',
		// 	exactMatch: false,
		// },
		{
			text: 'Report',
			routerLink: '/me/report',
			exactMatch: true,
		},
		{
			text: 'Notifications',
			routerLink: '/me/account/notifications',
			exactMatch: true,
		},
		{
			text: 'Profile',
			routerLink: 'me/account',
			exactMatch: false,
		},
		{
			text: 'Invite',
			routerLink: `/me/invite`,
			exactMatch: true,
		},
		/**
		 * @devnote Disabling insights for beta
		 */
		// {
		// 	text: 'Insights',
		// 	routerLink: '/insights',
		// 	exactMatch: false
		// }
	];

	constructor() {}

	ngOnInit() {}
}

import { ReportResultItem } from 'src/app/modules/user/services/user.service';
import { AmoebaChartDatum } from 'src/app/ui/chart/amoeba/amoeba.component';

export const makeAmoebaChartData = (
	data: ReportResultItem[],
	index?: number
): AmoebaChartDatum[] => {
	return data.map(
		(t: ReportResultItem) =>
			({
				id: t.id,
				// @ts-ignore
				axis: t.trait.name_general,
				value: t.dominance,
				rank: t.rank,
			} as AmoebaChartDatum)
	);
};

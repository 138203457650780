<div class="modal modal-trait fade d-block show" tabindex="-1" role="dialog" (click)="close($event)">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<button (click)="showAllTraits()" class="btn btn-outline-primary btn-sm"><small class="fa fa-chevron-left"></small> All Traits</button>
			</div>
            <div class="modal-body" *ngIf="insights$|async as insights; else loading">
					<h1>{{trait.name_general}}</h1>
					<div>
						<blockquote class="blockquote">
							<p>{{trait.haiku.content}}</p>
						</blockquote>
					</div>
					<div *ngFor="let item of traitContentItems" class="my-3">
						<sas-card>
							<sas-card-header>
								<strong class="card-title">{{item.title}}</strong>
							</sas-card-header>
							<sas-card-body>
								<p>{{trait[item.key].content}}</p>
							</sas-card-body>
						</sas-card>
					</div>
					<div class="my-3">
						<sas-card>
							<sas-card-header>
								<strong class="card-title">Quote</strong>
							</sas-card-header>
							<sas-card-body>
								<blockquote class="blockquote">
									<p>{{trait.quote.content}}</p>
									<footer class="blockquote-footer">{{trait.quote.author}}</footer>
								</blockquote>
							</sas-card-body>
						</sas-card>
					</div>

					<!-- @Revisit Insights -->
					<app-insight-group *ngIf="insights"[data]="insights"></app-insight-group>

			</div>
			<ng-template #loading>
				<div class="modal-body">
					<div class="trait-content">
						<div class="py-5">
							<app-loading-indicator [fullScreen]="false" indicatorText="Loading trait information..."></app-loading-indicator>
						</div>
					</div>
				</div>
			</ng-template>
		</div>
    </div>
</div>
<div class="modal-backdrop fade show" (click)="close($event)"></div>

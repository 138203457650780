import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { flatMap, first } from 'rxjs/operators';

import { selectUser } from '../store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private store: Store) {}

	/**
	 * Intercept HttpRequests and insert the active user's authorization token into the headers.
	 * @param req The request to manipulate
	 * @param next The handler we'll pass our new request to.
	 * @since 0.0.3
	 * @author `Austin Farmer <austin@farmer.codes>`
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		return this.store.select(selectUser).pipe(
			first(),
			flatMap(user => {
				/**
				 * Don't waste the time cloning and manipulating the request
				 * if we don't have an auth token.
				 */
				return next.handle(
					!!user
						? req.clone({
								// setHeaders is an Angular defined convenience.
								setHeaders: {
									Authorization: `Bearer ${user.token}`
								}
						  })
						: req
				);
			})
		);
	}
}

import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
	@Input() contained = true;

	@Input() showLinks = true;

	links = [
		{ url: '/privacy-policy', text: 'Privacy' },
		{ url: '/terms', text: 'Terms' },
		{ url: '/cookies', text: 'Cookies' },
		{ url: '/auth/logout', text: 'Logout' },
	];

	get currentYear() {
		return new Date().getFullYear();
	}
}

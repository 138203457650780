import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { mapTo, tap } from 'rxjs/operators';
import { SurveyService } from '../services/survey.service';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root'
})
export class SurveyCheckService implements CanActivate, CanDeactivate<any> {
	constructor(
		private userService: UserService,
		private surveyService: SurveyService
	) {}
	/**
	 * Load surveys during login
	 *
	 */
	async canActivate(): Promise<boolean> {
		await this.surveyService.init();
		return true;
	}

	/**
	 * Run Survey Checks
	 *
	 */
	canDeactivate(
		component,
		currentRoute,
		currentState,
		nextState
	): Observable<boolean> {
		return this.userService.user.pipe(
			tap(user => {
				if (user && user.preferences) {
					this.checkShowAlphaPostAssessmentSurvey(user.preferences);
				}
			}),
			mapTo(true)
		);
	}

	/**
	 * Conditions for showing survey:
	 * 1. has_seen_alpha... is false AND
	 * 2. some survey questions have not been answered
	 * @param userPreferences
	 */
	private checkShowAlphaPostAssessmentSurvey(userPreferences) {
		if (!userPreferences.has_seen_alpha_post_assessment_survey) {
			const alphaPostAssessmentSurveyId = 1;
			if (
				this.surveyService.shouldShowSurvey(alphaPostAssessmentSurveyId)
			) {
				this.surveyService.showSurvey(alphaPostAssessmentSurveyId);
			}
		}
	}
}

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TraitsModalService {
	// All Traits Modal
	private _showTraitModal = false;
	get showTraitModal() {
		return this._showTraitModal;
	}
	set showTraitModal(show: boolean) {
		this._showTraitModal = show;
	}
	private _search: string = '';
	public get search() {
		return this._search;
	}
	public set search(value: string) {
		this._search = value;
	}

	// Single Trait Modal
	private _showTraitDetailModal = false;
	get showTraitDetailModal() {
		return this._showTraitDetailModal;
	}
	set showTraitDetailModal(show: boolean) {
		this._showTraitDetailModal = show;
	}
	private _currentTraitId = null;
	public get currentTraitId() {
		return this._currentTraitId;
	}
	public set currentTraitId(id: number) {
		this._currentTraitId = id;
	}

	public showTraitDetailModalForTrait(id: number) {
		this.currentTraitId = id;
		this.showTraitDetailModal = true;
	}

	public hideTraitDetailModal() {
		this.currentTraitId = null;
		this.showTraitDetailModal = false;
	}

	constructor() {}
}

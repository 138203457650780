import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-invite-form',
	templateUrl: './invite-form.component.html',
	styleUrls: ['./invite-form.component.scss']
})
export class InviteFormComponent implements OnInit {
	submitting = false;

	inviteCopied = false;

	inviteUrl = null;

	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email])
	});

	constructor(private userService: UserService) {}

	ngOnInit() {}

	onSubmit() {
		const email = this.form.controls.email.value;

		this.submitting = true;
		this.userService
			.inviteFriend(email)
			.pipe(tap(() => (this.submitting = false)))
			.subscribe(inviteUrl => {
				this.inviteUrl = inviteUrl;
			});
	}

	reset() {
		this.submitting = false;
		this.inviteUrl = null;
		this.form.controls.email.patchValue('');
	}

	handleInviteCopied() {
		this.inviteCopied = true;
		const timeout = setTimeout(() => {
			this.inviteCopied = false;
			clearTimeout(timeout);
		}, 1500);
	}
}

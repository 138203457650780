import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';

@Component({
  selector: 'app-trait-icon-circle',
  templateUrl: './trait-icon-circle.component.html',
  styleUrls: ['./trait-icon-circle.component.scss']
})
export class TraitIconCircleComponent implements OnInit {

  @Input() 
  trait: Trait;

  @Input()
  badge: string|number;

  @Input()
  border = false;

  @Input()
  showName = false;

  @HostBinding('attr.title') title: string;

  get traitSafeName(): string {
    return this.trait.name_general.replace(/[,.\&]/g, '').replace(/[\ ]+/g, '-').toLowerCase();
  } 

  ngOnInit(): void {
    this.title = this.trait.name_general;
  }

}

<div class="container py-3">
    <div class="row justify-content-center align-items-center">
        
        <div class="col-lg-4">
            <sas-card>
                <sas-card-body>
                    <p><strong>The ShareaStrength<sup>&trade;</sup> Strengths</strong></p>
                    <p>Here is the complete list of our ShareaStrength&trade; strengths. Once you've taken
                        you're
                        assessment, we'll evaluate your answers to show you which strengths are most dominant in your
                        personality, and which strengths tend to take a back seat. Click on any of the strengths below
                        to
                        learn more.</p>
                </sas-card-body>
            </sas-card>
        </div>

        <div class="col-lg-6">
            <div *ngIf="traits$|async as traits">
                <!-- @Stolen from UI/Feedback/SelectTop5 -->
                <div class="trait-feedback-grid trait-feedback-items">
                    <a class="trait-feedback-item"
                        *ngFor="let trait of traits"
                        [routerLink]="['/', 'traits', trait.id]"
                    >
                        <app-trait-icon-block [trait]="trait"></app-trait-icon-block>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>

import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { UserOnboardingModalService } from './user-onboarding-modal.service';
import * as UserActions from 'src/app/store/user/user.actions';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';

@Component({
	selector: 'app-user-onboarding-modal',
	templateUrl: './user-onboarding-modal.component.html',
	styleUrls: ['./user-onboarding-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserOnboardingModalComponent implements OnInit {
	@ViewChild('slider') slider;

	slides = [
		{
			slug: 'welcome',
			title: 'Welcome.',
			content: `Welcome to the ShareaStrength community. Continue for a quick overview.`,
			image: null
		},
		{
			slug: 'top-5',
			title: 'Your top 5 traits.',
			content: `Learn more about yourself by reading about your innate strengths and blindspots.`,
			image: '/assets/img/onboarding/traits.jpg'
		},
		{
			slug: 'feed',
			title: 'The Home Feed.',
			content: `Get a birdseye view of everything occuring on SAS. Learn insights about yourself and others.`,
			image: '/assets/img/onboarding/feed.jpg'
		},
		{
			slug: 'invite',
			title: 'Invite Friends',
			content: `Invite friends and people in your network to take the assessment and compare.`,
			image: '/assets/img/onboarding/invite.jpg'
		},
		{
			slug: 'compare',
			title: 'Compare with friends.',
			content: `Guess their top 5 strengths and get valuable insight into your relationships.`,
			image: '/assets/img/onboarding/compare.jpg'
		},
		{
			slug: 'browse',
			title: 'Browse Profiles',
			content: `Gain insights and build stronger relationships by comparing and contrasting your traits.`,
			image: '/assets/img/onboarding/browse.jpg'
		}
		// {
		// 	slug: 'help',
		// 	title: 'Have a question?',
		// 	content:
		// 		'Click the <i class="fas fa-bullhorn"></i> icon in the bottom right at any time to give feedback, ask a question, or report a bug.',
		// 	image: '/assets/img/onboarding/help.jpg'
		// }
	];

	constructor(
		private store: Store,
		private userPreferencesService: UserPreferencesService,
		public userOnboardingModalService: UserOnboardingModalService
	) {}

	ngOnInit() {}

	async close(event = null) {
		if (event && !event.target.classList.contains('modal')) {
			return false;
		}
		await this.userPreferencesService
			.updateUserPreference('has_seen_dashboard_onboarding_modal', true)
			.toPromise();
		this.store.dispatch(
			UserActions.refresh({
				preferences: {
					has_seen_dashboard_onboarding_modal: true
				}
			})
		);
		this.userOnboardingModalService.showModal = false;
	}

	handleContinueClick() {
		if (this.slider.currentIndex === this.slides.length - 1) {
			return this.close();
		}
		this.slider.next();
	}
}

import { Component, ElementRef, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@sentry/angular';
import { Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { TraitResult } from 'src/app/modules/me/services/me.service';
import {
	ReportResultItem,
	UserService,
} from 'src/app/modules/user/services/user.service';
import { selectUser } from 'src/app/store';
import { getTraitSafeName } from 'lib/trait/get-safe-name';
import {
	AmoebaChartDatum,
	AmoebaChartOptions,
} from 'src/app/ui/chart/amoeba/amoeba.component';
import { makeAmoebaChartData } from 'lib/user/make-amoeba-chart-data';
import { ReportPageComponent } from './components/report-page/report-page.component';
import { WindowProviderService } from 'src/app/providers/window-provide.service';
// import * as printJs from 'print-js';

@Component({
	selector: 'app-report-export',
	templateUrl: './report-export.component.html',
	styleUrls: ['./report-export.component.scss'],
})
export class ReportExportComponent implements OnInit {
	pageNumber;

	amoebaChartOptions: AmoebaChartOptions;

	user$: Observable<User> = this.store.select(selectUser);
	userTraits$: Observable<TraitResult[]> = this.user$.pipe(
		filter((u) => u !== null),
		switchMap((u) => this.userService.getOrderedTraitsForUser(u.id, true))
	);

	flow$ = this.userTraits$.pipe(map((t) => t.slice(0, 5)));
	inBetween$ = this.userTraits$.pipe(map((t) => t.slice(5, 20)));
	friction$ = this.userTraits$.pipe(map((t) => t.slice(20)));

	amoebaData$: Observable<AmoebaChartDatum[][]> = this.userTraits$.pipe(
		map((traits: ReportResultItem[]) => [makeAmoebaChartData(traits)])
	);
	get currentYear() {
		return new Date().getFullYear();
	}

	constructor(
		private store: Store,
		private userService: UserService,
		private el: ElementRef,
		public windowProvider: WindowProviderService
	) {}

	ngOnInit(): void {
		this.amoebaChartOptions = {
			w: 622,
			h: 622,
			margin: {
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
			},
			strokeWidth: 4,
			axisColor: 'var(--primary)',
			labelColors: ['var(--primary)', 'var(--black)'],
			colors: ['var(--primary)', 'var(--black)'],
			background: 'transparent',
			showAxes: true,
			showAxesLabels: true,
			showNodeRanks: true,
			showAxesIcons: false,
			forExport: true,
		};
		setTimeout(() => {
			this.setupPrintStyles();
		}, 500);
	}

	setupPrintStyles() {
		console.log(this.windowProvider.window.outerHeight);
	}

	getPageNumber(pageId: string) {
		return (
			Array.from(this.el.nativeElement.children).findIndex(
				(page: HTMLElement) => page.id === pageId
			) + 1
		);
	}

	getTraitSafeName = getTraitSafeName;

	getCurrentPageNumber(start?: number) {
		if (start) {
			this.pageNumber = start;
		}
		let pageNumber = this.pageNumber;
		this.pageNumber++;
		return pageNumber;
	}

	scrollTo(event: any, page: any): void {
		console.log(event, page);
		event.preventDefault();
		page.el.nativeElement.scrollIntoView();
	}
}

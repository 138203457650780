<sas-card>
  <sas-card-header><strong>{{ trait.name_general }}</strong></sas-card-header>
  <div class="trait-icon-wrapper bg-primary d-flex align-items-center justify-content-center p-3 py-5">
    <div class="w-25">
        <app-trait-icon-circle [trait]="trait"></app-trait-icon-circle>
    </div>
  </div>
  <sas-card-body>
    <div class="trait-information-block my-5" *ngFor="let section of contentSections">
      <p class="trait-information-heading text-uppercase text-muted text-heading">{{ section }}</p>
      <p class="trait-information-content">{{ trait[section].content }}</p>
    </div>
  </sas-card-body>
</sas-card>
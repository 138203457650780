import { NgModule } from '@angular/core';

import { UserImageDirective } from './user-image.directive';
import { SasIconDirective } from './sas-icon.directive';
import { FullHeightChildrenDirective } from './full-height-children.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { LayoutDirective } from './layout.directive';
import { TraitsModalToggleDirective } from './traits-modal-toggle.directive';
import { ImageTintedDirective } from './image-tinted.directive';
import { TypeaheadPostalcodeDirective } from './typeahead-postalcode.directive';
import { TypeaheadOccupationDirective } from './typeahead-occupation.directive';
import { OnVisibleDirective } from './on-visible.directive';
import { TemplateVariableDirective } from './template-variable.directive';
import { LazyLoadedImgDirective } from './lazy-loaded-img.directive';
import { CopyClipboardDirective } from './copy-clipboard.directive';
import { RequestFeedbackDirective } from './request-feedback.directive';
import { SpriteIconDirective } from './sprite-icon.directive';

@NgModule({
	imports: [],
	declarations: [
		UserImageDirective,
		SasIconDirective,
		FullHeightChildrenDirective,
		InfiniteScrollDirective,
		LayoutDirective,
		TraitsModalToggleDirective,
		ImageTintedDirective,
		TypeaheadPostalcodeDirective,
		TypeaheadOccupationDirective,
		OnVisibleDirective,
		TemplateVariableDirective,
		LazyLoadedImgDirective,
		CopyClipboardDirective,
		RequestFeedbackDirective,
		SpriteIconDirective
	],
	exports: [
		UserImageDirective,
		SasIconDirective,
		FullHeightChildrenDirective,
		InfiniteScrollDirective,
		LayoutDirective,
		TraitsModalToggleDirective,
		ImageTintedDirective,
		TypeaheadPostalcodeDirective,
		TypeaheadOccupationDirective,
		OnVisibleDirective,
		TemplateVariableDirective,
		LazyLoadedImgDirective,
		CopyClipboardDirective,
		RequestFeedbackDirective,
		SpriteIconDirective
	]
})
export class DirectivesModule {}

import { Component, OnInit } from '@angular/core';
import { TraitService } from 'src/app/services/trait.service';
import { Trait } from 'src/app/interfaces/trait';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-traits',
  templateUrl: './traits.component.html',
  styleUrls: ['./traits.component.scss']
})
export class TraitsComponent {

  traits$: Observable<Trait[]> = this.traitService.traits.pipe(map(traits => this.alphabetizeTraits(traits)));

  constructor(private traitService: TraitService) { }

  alphabetizeTraits(traits: Trait[]): Trait[] {
    return traits.sort((a, b) => a.name_general > b.name_general ? 1 : -1);
  }

}

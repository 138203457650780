import {
	Component,
	OnInit,
	Input,
	ElementRef,
	HostBinding,
	Output,
	EventEmitter,
	SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowProviderService } from 'src/app/providers/window-provide.service';
import { getTraitSafeName } from 'lib/trait/get-safe-name';
import { ScaleLinear, ScaleOrdinal } from 'd3';

export interface AmoebaChartDatum {
	id: number;
	axis: string;
	value: number;
	rank?: number;
}

export interface AmoebaChartOptions {
	w?: number; // Width of the circle
	h?: number; // Height of the circle
	margin?: {
		top: number;
		right: number;
		bottom: number;
		left: number;
	}; // The margins of the SVG
	levels?: number; // How many levels or inner circles should there be drawn
	maxValue?: number; // What is the value that the biggest circle will represent
	labelFactor?: number; // How much farther than the radius of the outer circle should the labels be placed
	labelColors?: string[]; // Colors for text labels
	axisColor?: string; // Colors of radiating axis lines
	wrapWidth?: number; // The number of pixels after which a label needs to be given a new line
	opacityArea?: number; // The opacity of the area of the blob
	dotRadius?: number; // The size of the colored circles of each blog
	colorCircleStroke?: string; // Circle stroke color
	colorCircleFill?: string; // Circle fill color
	opacityCircles?: number; // The opacity of the circles of each blob
	opacityCirclesStroke?: number;
	strokeWidth?: number; // The width of the stroke around each blob
	roundStrokes?: boolean; // If true the area and stroke will follow a round path (cardinal-closed)
	colors?: string[]; // Colors for lines
	background?: string; // BG Color
	gradients?: boolean; // use gradient stroke
	scaleMaxValue?: boolean; // scaleMaxValue to results, or use 100
	// V2 Additions
	showAxes?: boolean; // show axis lines
	showAxesLabels?: boolean; // show axis labels outside graph
	showAxesIcons?: boolean; // show axis icons outside graph
	showAxesRanks?: boolean; // show trait rank outside graph at axis vertex
	showAxesScore?: boolean; // show percentage score outside graph at axis vertex
	showNodeRanks?: boolean; // show trait rank at graph/axis intersection
	showLabels?: boolean; // show trait rank labels
	showLevels?: boolean; // show concentric circle percentage levels
	forExport?: boolean; // Is this for download
	sorted?: boolean; // Has the data been sorted
	// @Deprecate
	showRanks?: boolean; // OLD show ranks at axis vertices
	showGrid?: boolean; // OLD showAxis lines and result
}

export interface AmoebaChartAxisDatum {
	axis: string;
	data: number[];
	rank: number[];
}

@Component({
	selector: 'sas-chart-amoeba',
	template: ``,
	styleUrls: ['./amoeba.component.scss'],
})
export class AmoebaComponent implements OnInit {
	@Input() debug = false;

	@Input() data: AmoebaChartDatum[][];

	@Input() options: AmoebaChartOptions = {};

	@Input() colors: string[];

	bgColor = 'transparent';
	@HostBinding('style.background-color')
	get _bgColor() {
		return this.sanitizer.bypassSecurityTrustStyle(this.bgColor);
	}

	@Input() activeTraitId: number;
	get _activeTraitId() {
		return this.activeTraitId;
	}

	@Output('traitClick') traitClick: EventEmitter<{
		axis: string;
		data: number[];
		rank: number[];
	}> = new EventEmitter();

	private id = 'chart-' + Math.round(Math.random() * 10e6);

	/**
	 * Indicates whether the chart is for one
	 * or multiple users.
	 */
	private isSingular = false;
	/**
	 * Maximum value of an axis
	 */
	private maxValue: number;
	/**
	 * Array of all trait(axis) names
	 */
	private allAxis: string[];
	/**
	 * Array of objects containing the trait name,
	 * an array of scores and an array of ranks
	 */
	private allAxisWithData: AmoebaChartAxisDatum[];
	/**
	 * Total number of traits (axis)
	 */
	private total: number;
	/**
	 * Radius of the chart
	 */
	private radius: number;
	/**
	 * Width in radians between each axis
	 */
	private angleSlice: number;
	/**
	 * Linear scale for the chart
	 */
	private rScale: ScaleLinear<number, number>;
	/**
	 * The wrapper SVG element that houses the chart
	 */
	private svg: any;
	/**
	 * The `g` element that houses the chart. Outer chart wrapper
	 */
	private g: any;
	/**
	 * The `g` element inner chart wrapper
	 */
	private innerWrapper: any;
	/**
	 * The `g` element that wraps the axis
	 */
	private axisWrapper: any;
	/**
	 * Chart Color
	 */
	private color: any;
	/**
	 * Chart config object
	 */
	private config: AmoebaChartOptions = {
		w: 600,
		h: 600,
		margin: { top: 20, right: 20, bottom: 20, left: 20 },
		levels: 5,
		maxValue: 1,
		labelFactor: 1.25,
		labelColors: ['var(--teal)', 'white'],
		axisColor: 'white',
		wrapWidth: 60,
		opacityArea: 0.25,
		dotRadius: 3,
		colorCircleStroke: '#fff',
		colorCircleFill: '#fff',
		opacityCircles: 0.1,
		opacityCirclesStroke: 1,
		strokeWidth: 3,
		roundStrokes: true,
		colors: ['var(--teal)', 'var(--white)'],
		background: 'transparent',
		gradients: false,
		scaleMaxValue: false,
		showAxes: false,
		showAxesLabels: true,
		showAxesRanks: false,
		showAxesScore: false,
		showAxesIcons: false,
		showNodeRanks: false,
		showLabels: false,
		showLevels: false,
		showRanks: false,
		showGrid: false, // deprecate
	};

	get computedSVGWidth() {
		return (
			this.config.w + this.config.margin.left + this.config.margin.right
		);
	}

	get computedSVGHeight() {
		return (
			this.config.h + this.config.margin.top + this.config.margin.bottom
		);
	}

	constructor(
		private element: ElementRef,
		private sanitizer: DomSanitizer,
		private windowProvider: WindowProviderService
	) {}

	ngOnInit() {
		if (this.debug) {
			console.log('Amoeba init', this.data, this.options);
		}
		this.setup();
	}

	/**
	 * Temporary fix to force re-render when our data
	 * is updated.
	 * @author LWK
	 */
	ngOnChanges(changes: SimpleChanges) {
		if (changes.data && changes.data.previousValue) {
			if (
				changes.data.previousValue.length > 1 &&
				changes.data.previousValue[1].map((d) => d.id).join('') !==
					changes.data.currentValue[1].map((d) => d.id).join('')
			) {
				this.setup();
			}
		}
	}

	/**
	 * Sort the chart data by trait name alphabetically
	 */
	private sortDataByTraitOrder(
		a: AmoebaChartDatum,
		b: AmoebaChartDatum
	): number {
		return a.axis === b.axis ? 0 : a.axis > b.axis ? 1 : -1;
	}

	/**
	 * D3 formatter for interpretting datum
	 */
	private Format = d3.format('%');

	/**
	 * Setup the chart data
	 *
	 * Updates all configurable values and merges input
	 * width defalt config.
	 */
	private setup = () => {
		this.isSingular = this.data.length === 1;

		/**
		 * Revisit color configuration
		 * @devnote do this after merging configs?
		 */
		if (this.colors) {
			this.config.colors = [...this.colors];
		}

		this.config = {
			...this.config,
			...this.options,
		};

		/**
		 * Sorting
		 *
		 * Sort by `trait.name_general` alphabetically.
		 *
		 * @todo it would be nice to sort these by QUADRANT
		 * and then ALPHABETICALLY. This may be possible
		 * down the line.
		 * @author LWK<lew@dankestudios.com>
		 */
		if (!this.config.sorted) {
			this.data.forEach((d) => d.sort(this.sortDataByTraitOrder));
		} else {
			console.log('Data was pre-sorted');
		}

		/**
		 *
		 * DATA setup for the chart starts here
		 *
		 */

		this.allAxis = this.data[0].map((i) => i.axis);

		this.allAxisWithData = this.data
			.reduce((a, d) => [...a, ...d], []) //combine
			.reduce(
				(a, c, i) => {
					const group = a.find((item) => item.axis === c.axis);
					group.data.push(c.value);
					group.rank.push(c.rank);
					return a;
				},
				this.data[0].map((d) => ({ axis: d.axis, data: [], rank: [] }))
			);

		this.maxValue = Math.max(
			this.config.maxValue,
			d3.max(this.data, (i) => d3.max(i.map((o) => o.value)))
		);

		if (!this.config.scaleMaxValue) {
			this.maxValue = 100;
		}

		this.total = this.allAxis.length;

		this.radius = Math.min(this.config.w / 2, this.config.h / 2);

		this.angleSlice = (Math.PI * 2) / this.total;

		this.color = d3.scaleOrdinal().range(this.config.colors);

		this.bgColor = this.config.background;

		this.rScale = d3
			.scaleLinear()
			.range([0, this.radius])
			.domain([0, this.maxValue]);

		if (this.debug) {
			console.log('Predraw', this);
		}
		this.draw();
	};

	private draw = () => {
		this.removeExistingSVG();

		/**
		 * @REVISIT
		 */
		const showGrid = this.config.showGrid;

		this.createSVG();
		this.createWrapper();
		this.createInnerWrapper();
		this.createDefsAndAppendGradients();

		const radarLines = this.createRadarLines();

		this.createAxisWrapper();

		if (this.config.showLevels) {
			const levelCircles = this.createLevelCircles();
		}

		const isSingular = this.isSingular;

		console.warn('FIX AXIS HOVER');
		const axisHoverIn = this.config.forExport
			? function () {}
			: function (d, i) {
					d3.select(this)
						.select('.axis-line')
						.transition()
						.duration(200)
						.style('stroke-opacity', '.1');
					d3.select(this)
						.select('.legend .trait-name')
						.transition()
						.duration(200)
						.style('fill-opacity', 1);
					d3.select(this)
						.selectAll('.rank-circle')
						.transition()
						.duration(200)
						.style('opacity', 1);
			  };

		const axisHoverOut = this.config.forExport
			? function () {}
			: function (d, i) {
					d3.select(this)
						.select('.axis-line')
						.transition()
						.duration(200)
						.style('stroke-opacity', isSingular ? '0.1' : 0);
					d3.select(this)
						.select('.legend .trait-name')
						.transition()
						.duration(200)
						.style('fill-opacity', isSingular ? '0.1' : 0);
					d3.select(this)
						.selectAll('.rank-circle')
						.transition()
						.duration(200)
						.style('opacity', 0);
			  };

		/**
		 * Create AXIS groups that house any labels
		 */
		const axis = this.axisWrapper
			.selectAll('.axis')
			.data(this.allAxisWithData)
			.enter()
			.append('g')
			.attr('class', 'axis')
			.on('click', (d) => this.traitClick.emit(d))
			.on('mouseover', axisHoverIn)
			.on('mouseout', axisHoverOut);

		/**
		 * Individual axis lines
		 */
		const axisLines = axis
			.append('line')
			.attr('class', 'axis-line')
			.attr('x1', 0)
			.attr('y1', 0)
			.attr('x2', (d, i) => {
				return (
					this.rScale(
						this.maxValue * (this.config.forExport ? 0.95 : 0.85)
					) * Math.cos(this.angleSlice * i - Math.PI / 2)
				);
			})
			.attr('y2', (d, i) => {
				return (
					this.rScale(
						this.maxValue * (this.config.forExport ? 0.95 : 0.85)
					) * Math.sin(this.angleSlice * i - Math.PI / 2)
				);
			})
			.style('stroke', this.config.axisColor)
			.style(
				'stroke-opacity',
				this.config.showAxes || showGrid ? '.1' : '0'
			)
			.style('stroke-width', '2px');

		/**
		 * Transparent overlay groups that help with hover capture
		 */
		const hoverLines = axis
			.append('line')
			.attr('class', 'axis-hover-line')
			.attr('x1', 0)
			.attr('y1', 0)
			.attr('x2', (d, i) => {
				return (
					this.rScale(this.maxValue * 1) *
					Math.cos(this.angleSlice * i - Math.PI / 2)
				);
			})
			.attr('y2', (d, i) => {
				return (
					this.rScale(this.maxValue * 1) *
					Math.sin(this.angleSlice * i - Math.PI / 2)
				);
			})
			.style('stroke', 'transparent')
			.style('stroke-width', '20px');

		const labels = axis
			.append('g')
			.style('opacity', this.isSingular ? 'var(--opacity, 0)' : 1)
			.attr('class', 'legend')
			.attr('id', (d) => `legend-${getTraitSafeName(d.axis)}`);

		/**
		 * Draw axis trait name
		 */
		if (this.config.showAxesLabels) {
			const labelText = labels
				.append('text')
				.attr('id', (d) => `trait-name-${getTraitSafeName(d.axis)}`)
				.style(
					'fill-opacity',
					this.isSingular ? (this.config.forExport ? 1 : 0.1) : 0
				)
				.style('font-size', '14px')
				.style('font-weight', '400')
				.style('font-family', 'var(--font-family-sans-serif)')
				.style('text-transform', 'capitalize')
				.style('cursor', 'pointer')
				.attr('text-anchor', 'middle')
				.attr('class', 'trait-name')
				.attr('dy', this.config.showAxesIcons ? '-1.25em' : '0.35em')
				.attr('x', (d, i) => {
					return (
						this.rScale(
							this.maxValue *
								(this.config.forExport ? 0.85 : 0.75) *
								this.config.labelFactor
						) * Math.cos(this.angleSlice * i - Math.PI / 2)
					);
				})
				.attr('y', (d, i) => {
					return (
						this.rScale(
							this.maxValue *
								(this.config.forExport ? 0.85 : 0.75) *
								this.config.labelFactor
						) * Math.sin(this.angleSlice * i - Math.PI / 2)
					);
				})
				.html((d, i) =>
					this.config.forExport
						? d.axis.split(' ').reduce((a, c, _i) => {
								const x =
									this.rScale(
										this.maxValue *
											(this.config.forExport
												? 0.85
												: 0.75) *
											this.config.labelFactor
									) *
									Math.cos(this.angleSlice * i - Math.PI / 2);
								const y =
									this.rScale(
										this.maxValue *
											(this.config.forExport
												? 0.85
												: 0.75) *
											this.config.labelFactor
									) *
									Math.sin(this.angleSlice * i - Math.PI / 2);
								return `${a}<tspan x="${x}" y="${y}" dy="${
									_i * 1.1 + 1
								}em">${c}</tspan>`;
						  }, '')
						: d.axis
				);
		}

		/**
		 * @Todo REVISIT not sure what this is doing
		 */
		if (this.config.showAxesIcons) {
			const traitIcon = labels
				.append('circle')
				.attr('class', 'trait-axis-icon')
				.attr('r', 12)
				.attr('fill', 'var(--white)')
				.attr('stroke', 'var(--primary)')
				.attr('stroke-width', 2)
				.attr('cx', (d, i) => {
					return (
						this.rScale(
							this.maxValue * 0.75 * this.config.labelFactor
						) * Math.cos(this.angleSlice * i - Math.PI / 2)
					);
				})
				.attr('cy', (d, i) => {
					return (
						this.rScale(
							this.maxValue * 0.75 * this.config.labelFactor
						) * Math.sin(this.angleSlice * i - Math.PI / 2)
					);
				});
			// .attr(
			// 	'xlink:xlink:href',
			// 	(d) =>
			// 		`/assets/svg/sprites/sprite.svg#sprite-${getTraitSafeName(
			// 			d.axis
			// 		)}`
			// );
		}

		if (this.config.showRanks) {
			this.data.forEach((_d, _i) =>
				this.addRankingCirclesToLabels(_i, labels)
			);
		}

		if (this.config.showAxesScore) {
			this.data.forEach((_d, _i) =>
				this.addTraitDominanceToLabels(_i, labels)
			);
		}

		if (this.config.showNodeRanks) {
			const traitRankNode = axis
				.append('circle')
				.attr('fill', (d, i) => {
					const rank = d.rank[0];
					return rank < 6
						? 'var(--primary)'
						: rank > 20
						? 'var(--navy)'
						: 'var(--white)';
				})
				.attr('stroke', (d, i) => {
					const rank = d.rank[0];
					return rank < 6
						? 'var(--primary)'
						: rank > 20
						? 'var(--navy)'
						: 'var(--primary)';
				})
				.attr('stroke-width', '2')
				.attr('r', '12')
				.attr('cx', (d, i) => {
					return (
						this.rScale(
							(this.maxValue *
								this.config.labelFactor *
								0.8 * // where is this coming from?
								d.data[0]) /
								100
						) * Math.cos(this.angleSlice * i - Math.PI / 2)
					);
				})
				.attr('cy', (d, i) => {
					return (
						this.rScale(
							(this.maxValue *
								this.config.labelFactor *
								0.8 * // where is this coming from?
								d.data[0]) /
								100
						) * Math.sin(this.angleSlice * i - Math.PI / 2)
					);
				});

			const traitRankNodeText = axis
				.append('text')
				.style('text-align', 'center')
				.style('font-size', '14px')
				.style('font-weight', '400')
				.style('font-family', 'var(--font-family-sans-serif)')
				.attr('text-anchor', 'middle')
				.attr('class', 'trait-rank')
				.attr('fill', (d, i) => {
					const rank = d.rank[0];
					return rank < 6 || rank > 20
						? 'var(--white)'
						: 'var(--primary)';
				})
				.attr('dy', '4')
				.attr('x', (d, i) => {
					return (
						this.rScale(
							(this.maxValue *
								this.config.labelFactor *
								0.8 * // where is this coming from?????
								d.data[0]) /
								100
						) * Math.cos(this.angleSlice * i - Math.PI / 2)
					);
				})
				.attr('y', (d, i) => {
					return (
						this.rScale(
							(this.maxValue *
								this.config.labelFactor *
								0.8 * // where is this coming from?????
								d.data[0]) /
								100
						) * Math.sin(this.angleSlice * i - Math.PI / 2)
					);
				})
				.text((d, i) => d.rank);
		}

		/**
		 * Rerender labels to fix z-indexing
		 */
		if (this.isSingular && this.config.showAxesLabels) {
			const labelText = axis
				.append('use')
				.attr('style', '--opacity: 1')
				.attr(
					'xlink:xlink:href',
					(d) => `#legend-${getTraitSafeName(d.axis)}`
				);
		}
	};

	private createDefsAndAppendGradients = () => {
		const defs = this.svg.append('defs');

		this.data.forEach((d, i) => {
			const linearGradient = defs
				.append('linearGradient')
				.attr('id', 'linear-gradient-' + i);

			linearGradient
				.append('stop')
				.attr('offset', '0%')
				.attr('stop-opacity', '.2')
				.attr('stop-color', this.color(i));

			linearGradient
				.append('stop')
				.attr('offset', '100%')
				.attr('stop-opacity', '1')
				.attr('stop-color', this.color(i));
		});

		return defs;
	};

	/**
	 * Create the HOST SVG element
	 *
	 */
	private createSVG: any = () => {
		this.svg = d3
			.select(this.element.nativeElement)
			.append('svg')
			.attr('width', this.computedSVGWidth)
			.attr('height', this.computedSVGHeight)
			.style('overflow', 'visible')
			.attr('id', 'amoeba-' + this.id);
		return this.svg;
	};

	/**
	 * Create outer chart wrapper
	 *
	 */
	// @ts-ignore
	private createWrapper: any = () => {
		this.g = this.svg
			.append('g')
			.attr('class', 'amoeba-container')
			.attr(
				'transform',
				`translate( ${this.config.w / 2 + this.config.margin.left}, ${
					this.config.h / 2 + this.config.margin.top
				})`
			);
		return this.g;
	};

	/**
	 * Create inner chart wrapper
	 * @returns
	 */
	private createInnerWrapper: any = () => {
		this.innerWrapper = this.g
			.selectAll('.amoeba-wrapper')
			.data(this.data)
			.enter()
			.append('g')
			.attr('class', 'amoeba-wrapper');
		return this.innerWrapper;
	};

	/**
	 * Create axis wrapper
	 * @returns
	 */
	private createAxisWrapper: any = () => {
		this.axisWrapper = this.g.insert('g').attr('class', 'axis-wrapper');
		return this.axisWrapper;
	};

	/**
	 * Create radar lines
	 * @returns
	 */
	private createRadarLines: any = () => {
		const radarLines = this.innerWrapper
			.append('path')
			.attr('class', 'amoeba-stroke')
			.attr('d', (d) => this.radarLine()(d))
			.attr('stroke', (d, i) => {
				if (
					!this.config.gradients ||
					this.windowProvider.window.navigator.platform.toLowerCase() ==
						'iphone'
				) {
					console.log('Removing SVG Gradients for iPhone');
					return this.config.colors[i];
				}
				return 'url(#linear-gradient-' + i + ')';
			})
			.attr('stroke-width', `${this.config.strokeWidth}px`)
			.attr('fill', 'none');
		return radarLines;
	};

	/**
	 * Create concentric circle levels
	 */
	private createLevelCircles: any = () => {
		return this.axisWrapper
			.selectAll('.levels')
			.data(d3.range(1, this.config.levels + 1).reverse())
			.enter()
			.append('circle')
			.attr('class', 'grid-circle')
			.attr('r', (d, i) => (this.radius / this.config.levels) * d)
			.style('fill', this.config.colorCircleFill)
			.style('stroke', this.config.colorCircleStroke)
			.style('stroke-width', '2px')
			.style('stroke-opacity', this.config.opacityCirclesStroke)
			.style('fill-opacity', this.config.opacityCircles);
	};

	/**
	 * Radar line draw function
	 *
	 */
	private radarLine = () =>
		d3
			.lineRadial()
			.radius((d: any) => this.rScale(d.value))
			.angle((d, i) => i * this.angleSlice)
			// .curve(d3.curveNatural); // unclosed
			.curve(d3.curveCardinalClosed); // closed

	/**
	 * Clear the HOST Element of children
	 *
	 */
	private removeExistingSVG = () =>
		d3.select(this.element.nativeElement).select('svg').remove();

	// Wraps SVG text
	// @devnote this is broken
	private wrap(text, width) {
		text.each(function () {
			const text = d3.select(this);
			let words = text.text().split(/\s+/).reverse(),
				word,
				line = [],
				lineNumber = 0,
				lineHeight = 1.4, // ems
				y = text.attr('y'),
				x = text.attr('x'),
				dy = parseFloat(text.attr('dy')),
				tspan = text
					.text(null)
					.append('tspan')
					.attr('x', x)
					.attr('y', y)
					.attr('dy', dy + 'em');

			while ((word = words.pop())) {
				line.push(word);
				tspan.text(line.join(' '));
				if (tspan.node().getComputedTextLength() > width) {
					line.pop();
					tspan.text(line.join(' '));
					line = [word];
					tspan = text
						.append('tspan')
						.attr('x', x)
						.attr('y', y)
						.attr('dy', ++lineNumber * lineHeight + dy + 'em')
						.text(word);
				}
			}
		});
	}

	/**
	 * Add Circular Ranks beneath Trait Names
	 */
	private addRankingCirclesToLabels(currentIndex, labels) {
		console.log(currentIndex, labels);
		const singular = this.isSingular;

		const xPos = (d, i) => {
			return (
				this.rScale(this.maxValue * 0.75 * this.config.labelFactor) *
				Math.cos(this.angleSlice * i - Math.PI / 2)
			);
		};
		const yPos = (d, i) => {
			return (
				this.rScale(this.maxValue * 0.75 * this.config.labelFactor) *
				Math.sin(this.angleSlice * i - Math.PI / 2)
			);
		};
		const xPosCircle = (d, i) => {
			let x = xPos(d, i);
			if (singular) {
				return x;
			}
			return currentIndex % 2 === 0 ? x - 14 : x + 14;
		};
		const yPosCircle = (d, i) => {
			let y = yPos(d, i);
			if (singular) {
				return y;
			}
			return y + 23;
		};

		const rankCircle = labels
			.append('g')
			.attr('class', 'rank-circle')
			.style('opacity', this.isSingular || this.config.showAxes ? 1 : 0);

		// Rank BG
		const circle = rankCircle
			.append('circle')
			.attr('cx', xPosCircle)
			.attr('cy', yPosCircle)
			.attr('r', 12)
			.attr('fill', this.config.labelColors[currentIndex]);

		const tspan = rankCircle
			.append('text')
			.attr('text-anchor', 'middle')
			.attr('class', `trait-rank user-${currentIndex}`)
			.attr('font-size', '14px')
			.attr('font-weight', 'bold')
			// .attr('fill', this.config.labelColors[currentIndex])
			.attr('fill', 'var(--white)')
			.attr('x', xPos)
			.attr('dx', (d, i) => {
				if (singular) {
					return '0';
				}
				// Need this to be smarter
				if (this.data.length > 1) {
					return currentIndex % 2 === 0 ? '-1em' : '1em';
				}
			})
			.attr('y', yPos)
			.attr('dy', this.isSingular ? '.3em' : '2em')
			.text((d, i) => {
				return d.rank[currentIndex];
			});
	}

	/**
	 * Add Trait Dominace Percent Beneath Trait Names
	 */
	private addTraitDominanceToLabels(currentIndex, labels) {
		// Append User Scores
		const tspan = labels
			.append('text')
			.attr('text-anchor', 'middle')
			.attr('class', `trait-score user-${currentIndex}`)
			.attr('font-size', '12px')
			// .style('text-shadow', '0px 0px 4px var(--primary)')
			.attr('fill', this.config.labelColors[currentIndex])
			.attr('x', (d, i) => {
				return (
					this.rScale(
						this.maxValue * 0.75 * this.config.labelFactor
					) * Math.cos(this.angleSlice * i - Math.PI / 2)
				);
			})
			.attr('dx', (d, i) => {
				// Need this to be smarter
				if (this.data.length > 1) {
					return currentIndex % 2 === 0 ? '-2em' : '2em';
				}
			})
			.attr('y', (d, i) => {
				return (
					this.rScale(
						this.maxValue * 0.75 * this.config.labelFactor
					) * Math.sin(this.angleSlice * i - Math.PI / 2)
				);
			})
			.attr('dy', '1.6em')
			.text((d, i) => {
				// Convert to percent
				const factor = d.data[currentIndex] > 1 ? 1 : 100; // number will either be a fraction or percent
				return (d.data[currentIndex] * factor).toFixed(2) + '%';
			});
	}
}

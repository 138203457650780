<ng-container *ngIf="traits$ | async as traits">
	<!-- All Traits -->
    <sas-traits-modal
        *ngIf="traitsModalService.showTraitModal"
        [search]="traitsModalService.search"
        [traits]="traits"
        (dismiss)="traitsModalService.showTraitModal = false; traitsModalService.search = ''"
	></sas-traits-modal>
	<!-- Single Trait -->
	<sas-trait-modal
		*ngIf="traitsModalService.showTraitDetailModal"
		[traitId]="traitsModalService.currentTraitId"
		[traits]="traits"
		(dismiss)="traitsModalService.hideTraitDetailModal()"
	></sas-trait-modal>
</ng-container>

import { Component, OnInit, Input } from '@angular/core';
import { Trait } from 'src/app/interfaces/trait';

@Component({
  selector: 'app-trait-icon-block',
  templateUrl: './trait-icon-block.component.html',
  styleUrls: ['./trait-icon-block.component.scss']
})
export class TraitIconBlockComponent {

  @Input() trait: Trait;

  get traitSafeName(): string {
    return this.trait.name_general.replace(/[,.\&]/g, '').replace(/[\ ]+/g, '-').toLowerCase();
  } 

}

import { Injectable } from "@angular/core";
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from "@angular/router";
import { TraitService } from "src/app/services/trait.service";
import { Trait } from "src/app/interfaces/trait";
import { Observable, of } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class TraitResolverService implements Resolve<Observable<Trait>> {

    constructor(private traitService: TraitService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Trait> {
        // @Debug
        return this.traitService.getTrait(+route.paramMap.get('traitId'));
    }
}

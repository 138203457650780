import { Injectable, EventEmitter } from '@angular/core';
import { ToastConfig } from './toast/toast.component';
import { BehaviorSubject } from 'rxjs';

/**
 * Service for displaying short informative or
 * actionable messages as toasts in the right
 * half of the screen.
 * 
 * Note: let's look into adding ability to pass specific
 * triggering criteria such as a route or a time. May want
 * to move all of that to a persisted store.
 * 
 * @author LWK <lew@dankestudios.com>
 * 
 * @usage
 * ```ts
 * toastService.queueToast({
 *  title,
 *  meta,
 *  content,
 *  duration,
 * });
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /**
   * Stream through which we can pass the toasts information
   * to the toast outlet for rendering
   * 
   * @author LWK
   */
  private _toastQueue = new BehaviorSubject<ToastConfig>(null);
  public toastQueue = this._toastQueue.asObservable();

  constructor() {}

  /**
   * Sends the toast through to anything that might be
   * observing the toastQueue stream.
   * 
   * @author LWK
   * 
   * @param config toast information used to create the toast component
   */
  public queueToast(config: ToastConfig) {
    this._toastQueue.next(config);
  }
}

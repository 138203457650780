<div class="survey__question">
	<small class="mr-2" *ngIf="surveyService.checkSurveyQuestionIsRequired(survey, question)">
		<strong>*Required</strong>
	</small>
	<i class="fa fa-check text-success" *ngIf="complete"></i>
	<label class="d-block" [for]="'question-' + question.id">
		<span class="index mr-1" *ngIf="index !== null">{{index}}.</span>
		<span class="question">{{ question.question }}</span>
	</label>
	<!-- <pre>{{question|json}}</pre> -->
	<ng-container [ngSwitch]="question.type">

		<div *ngSwitchCase="questionTypes.RATING" class="form-group">
			<div class="form-options d-flex justify-content-between">
				<div
					*ngFor="let value of [1, 2, 3, 4, 5]"
					class="custom-control custom-radio custom-control-inline"
				>
					<input
						class="custom-control-input"
						type="radio"
						[name]="'question-' + question.id + '-' + value"
						[id]="'question-' + question.id + '-' + value"
						[value]="value+''"
						[(ngModel)]="question.response"
						(ngModelChange)="handleModelChange($event)"
					/>
					<label
						class="custom-control-label"
						[for]="'question-' + question.id + '-' + value"
						>{{ value }}</label
					>
				</div>
			</div>
			<div class="d-flex justify-content-between small">
				<p class="form-text">{{ question.rating_min_text }}</p>
				<p class="form-text">{{ question.rating_max_text }}</p>
			</div>
		</div>

		<div
			*ngSwitchCase="questionTypes.MULTIPLE_SELECT"
			class="form-group"
		>
			<!-- <select
				class="custom-select"
				multiple="true"
				[size]="question.options.length"
				[attr.aria-label]="question.question"
				[(ngModel)]="question.response"
				(ngModelChange)="handleModelChange($event)"
			>
				<option
					*ngFor="let option of question.options"
					[value]="option"
					>{{ option }}</option
				>
			</select> -->
			<div class="custom-control custom-checkbox" *ngFor="let option of question.options; let _i = index">
				<input
					type="checkbox"
					class="custom-control-input"
					(change)="handleCheckboxChange($event, option)"
					[checked]="question.response.indexOf(option) > -1"
					[id]="'question-'+question.id+'-'+_i">
				<label class="custom-control-label" [for]="'question-'+question.id+'-'+_i">{{option}}</label>
			  </div>
		</div>

		<div *ngSwitchCase="questionTypes.TEXT" class="form-group">
			<textarea
				[name]="'question-' + question.id"
				[(ngModel)]="question.response"
				(ngModelChange)="handleModelChange($event)"
				rows="5"
				class="form-control"
				placeholder="Type your answer..."
			></textarea>
		</div>

	</ng-container>
</div>

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {
	private _fullWidth: boolean = false;
	public get fullWidth() {
		return this._fullWidth;
	}
	public set fullWidth(full: boolean) {
		this._fullWidth = full;
	}

	enableFullWidth() {
		this.fullWidth = true;
	}

	disableFullWidth() {
		this.fullWidth = false;
	}
}

import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { User } from 'src/app/store/user/user.model';

@Component({
	selector: 'user-image',
	template: `
		<figure class="figure">
			<img
				*ngIf="imageUrl"
				class="figure-img img-fluid"
				[src]="imageUrl"
				[attr.alt]="description"
				[attr.title]="description"
			/>
			<a *ngIf="isAuthUser" routerLink="/me/account/image"
				><i class="fas fa-cog"></i
			></a>
		</figure>
	`,
	styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent implements OnInit {
	placeholderImageUrl = '/assets/img/avatar.jpg';

	@Input() user: User;

	@Input() isAuthUser: boolean = false;

	@Input()
	@HostBinding('class.large')
	large = false;

	@Input()
	@HostBinding('class.small')
	small = false;

	get haveProfileImage(): boolean {
		return !!(
			this.user &&
			this.user.profile &&
			this.user.profile.image_path
		);
	}

	get imageUrl(): string {
		return this.haveProfileImage
			? this.user.profile.image_path
			: this.placeholderImageUrl;
	}

	get description(): string {
		if (null === this.user.profile) {
			return 'User Profile Image';
		}
		return (this.user.profile.name || 'User') + ' Profile Image';
	}

	ngOnInit() {}

	get randomImageUrl(): string {
		const randomInt = Math.round(Math.random() * 10e3);
		return `https://picsum.photos/200/200?random=${this.user.id}`;
	}
}
